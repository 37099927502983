const BBEEE = [
    { value: null, label: '--Select--' },
    { value: "LEVEL 1", label: "LEVEL 1" },
    { value: "LEVEL 2", label: "LEVEL 2" },
    { value: "LEVEL 3", label: "LEVEL 3" },
    { value: "LEVEL 4", label: "LEVEL 4" },
    { value: "LEVEL 5", label: "LEVEL 5" },
    { value: "LEVEL 6", label: "LEVEL 6" },
    { value: "LEVEL 7", label: "LEVEL 7" },
    { value: "LEVEL 8", label: "LEVEL 8" },
    { value: "NON COMPLIANCE", label: "NON COMPLIANCE" },
    { value: "NONE", label: "NONE" },
    ]

export default BBEEE;