import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import { faker } from '@faker-js/faker';


function Supplier(){
  const getRandomDate = () => {
    const startDate = new Date('2024-01-01');
    const endDate = new Date('2024-01-31');
    const randomDate = new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
    return randomDate.toISOString().split('T')[0];
  };
  
  const getRandomRating = () => {
    return Math.floor(Math.random() * 3) + 3; // Generates random ratings between 3 and 5
  };

  const getRandomPhoneNumber = () => {
    const phoneNumber = `+27-${Math.floor(Math.random() * 1000000000).toString().padStart(10, '0')}`;
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };
  
  const generateRandomSuppliers = () => {
    const suppliers = [];
    const categories = ['10', '15', '55', '39', '99', '88', '20',];
    for (let i = 0; i < 10; i++) {
      const firstName = faker.name.firstName();
      const lastName = faker.name.lastName();
      const randomCategory = categories[Math.floor(Math.random() * categories.length)];
      const supplier = {
        dateRegistered: getRandomDate(),
        companyName: `${lastName} ${firstName} Inc.`,
        category: randomCategory,
        contactPerson: `${firstName}`,
        email: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@example.com`,
        phone: getRandomPhoneNumber(),
        // completedProjects: Math.floor(Math.random() * 100),
        rating: getRandomRating(),
      };
      suppliers.push(supplier);
    }
    return suppliers;
  };
  

  const suppliersData = generateRandomSuppliers();



      return (
     <div>
     <Header3/>
     <div>
        <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
          <div id="kt_toolbar_container" className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                Training
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
            <a href="newtraining" className="btn btn-primary">Create New Training</a> 
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">All Trainings</span>
                </h3>
                <div className="card-toolbar">
                  <input type='text' className='form-control' placeholder='Search keyword'/>
                 </div>
              </div>
              <div className="card-body py-3">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                    {/*begin::Table head*/}
                    <thead>
                      <tr className="fw-bold text-muted">
                        
                        <th className="min-w-150px">Date Registered</th>
                        <th className="min-w-150px">Training name</th>
                        {/* <th className="min-w-150px">Cohort name</th> */}
                        <th className="min-w-140px">Total Students</th>
                        <th className="min-w-120px">Coach</th>
                        {/* <th className="min-w-120px">Email</th>
                        <th className="min-w-120px">Phone</th> */}
                        <th className="min-w-100px text-end">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {suppliersData.map((supplier, index) => (
                        <tr key={index}>
                          <td>{supplier.dateRegistered}</td>
                          <td>November 2022 - ABC LTD</td>
                          <td>{supplier.category}</td>
                          <td>{supplier.contactPerson}</td>
                          {/* <td>{supplier.email}</td>
                          <td>{supplier.phone}</td> */}
                          {/* <td>{supplier.rating}</td> */}
                          <td className="text-end"><a href='/viewtraining' className='btn btn-danger'>View </a></td>
                        </tr>
                      ))}
                      
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              {/*begin::Body*/}
            </div>
          </div></div></div>
     <Footer/>
              </div>
               
        );
 
  };

export default Supplier;
