import React, {useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import DataTable from "react-data-table-component";
import Spinner from 'react-bootstrap/Spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import withReactContent from 'sweetalert2-react-content'


function Viewsupplier(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState(['']);
  const [summ_info, setsum] = useState([]);
  const [int_info, setlength] = useState('');
  const mysum = localStorage.getItem('ladpsouthafricasuppliersummaryarray');
  const orgURL = BASE_URL+'/fetchSupplierbyID'
  const linkURL = BASE_URL+'/supplier_requests'
  const enableURL = BASE_URL+'/activateUser'
  const disableURL = BASE_URL+'/deactivateSupplier'
  const interactionsummaryURL = BASE_URL+'/fetchSuppliersummary'
  const token = localStorage.getItem("ladpsouthafricaaccesstoken");
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const parsedSum = JSON.parse(mysum)
  console.log(mysum)
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const editorganization = (selectedId) => {
    const selectedID = selectedId;
    if (selectedID) {
      const nextPageUrl = '/vieworganization'; 
      window.location.href = nextPageUrl;
      window.location.href = "editsupplier?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${selectedId} not found`);
    }
  }

  const headers2 = { 
    headers: {
      'AccessToken': token,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'supplier_id': myidd
  }

  

  const getsuppinfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(0);
      }else{
        const sortedArray = response.data.data[0];
        console.log(sortedArray)
        setorg(sortedArray);
        setorg(sortedArray);
      }
    });
  };
  

  const getsuppsummary = async (e) => {
    axios.post(interactionsummaryURL, body, headers2)
      .then((response) => {
        console.log("response received: ", response);
        if (!response || !response.data || !response.data.data || response.data.data.length === 0) {
          setsum('');
        } else {
          // Assuming that response.data.data[0] contains the value you want to set to sum
          const sortedArray = response.data.data;
          const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
          const lengtharray = response.data.data.length;
          setlength(lengtharray);
          setsum(sortedArray);
          setsum(sortedArray);
          setsum(sortedArray);
          setsum(sortedArray);
          setsum(sortedArray);
          setsum(sortedArray);
          setsum(sortedArray);
          console.log(summ_info);
        }
      })
      .catch((error) => {
        console.error("Error fetching org summary:", error);
        setsum(''); // Set sum to 0 in case of error
      });
  };

  const columns = [
    {
      name: 'DATE',
      selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
      sortable: true,
      width: "10rem" ,
        cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
  },
  {
    name: 'DESCRIPTION ',
    selector: (row) => row.description,
    sortable: true,
    width: '12rem',
    cellExport: row => row.description 

  },

  {
    name: 'SUPPLIER',
    selector: (row) => row.supplier_name,
    sortable: true,
    width: '10rem',
    cellExport: row => row.supplier_name 

  },
  {
    name: 'REVIEW',
    selector: (row) => row.review,
    sortable: true,
    width: '9rem',
    cellExport: row => row.review 

  },
  {
    name: 'ACTIONS',
    button: true,
    width: '10rem',
    cell: (row) => <button onClick={() => vieworganization(row.id)} className="btn btn-sm btn-info btn-active-light-primary">View</button>

  },
];

const data=summ_info;
const tableData = {
  columns,
  data
};
  
const vieworganization = (selectedId) => {
  const selectedID = selectedId;
  if (selectedID) {
    const nextPageUrl = '/orgviewquote'; 
    window.location.href = nextPageUrl;
    window.location.href = "viewquote?myid=" + selectedID;
  } else {
    console.error(`Data for ID ${selectedId} not found`);
  }
}
  

  const enable = async (e) => {
    e.preventDefault();
    if (!myidd) {
      MySwal.fire({
        title: <p>Unable to get Client ID</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
       MySwal.showLoading()
        Swal.close()
        MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(enableURL, body, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             Swal.fire({
               title: "Successful",
               text: "Client Updated successfully",
               icon: "success",
               showCancelButton: false,
               confirmButtonColor: '#3085d6',
               confirmButtonText: "Okay",
               closeOnCancel: true,
             }).then(result => {
           window.location.reload(false);
             })
             })
             .catch((err) => {
             Swal.close()
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };

  const disable = async (e) => {
    e.preventDefault();
    if (!myidd) {
      MySwal.fire({
        title: <p>Unable to get Client ID</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
       MySwal.showLoading()
        Swal.close()
        MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(disableURL, body, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             Swal.fire({
               title: "Successful",
               text: "Client Updated successfully",
               icon: "success",
               showCancelButton: false,
               confirmButtonText: "Okay",
               confirmButtonColor: '#3085d6',
               closeOnCancel: true,
             }).then(result => {
           window.location.reload(false);
             })
             })
             .catch((err) => {
             Swal.close()
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };

  const requestquote = () => {
    const selectedID = myidd;
    if (selectedID) {
      const nextPageUrl = '/quote';
      window.location.href = nextPageUrl;
      window.location.href = "quote?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${myidd} not found`);
    }
  }

  const linkbody = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'supplier_id': myidd,
    'supplier_name': org_info.suppliername,
    'supplier_email': org_info.contact_mail,
  }

  const requestlink = (myidd) => {
    console.log(myidd)
    if (myidd) {
      MySwal.fire({
        text: 'Send link to supplier to update his information?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
      }).then((result) => {
        console.log(linkbody)
        if (result.isConfirmed) {
          MySwal.showLoading();
          axios.post(linkURL, linkbody, headers2)
            .then((response) => {
              console.log("response received: ", response);
              Swal.fire({
                title: "Successful",
                text: "Link sent successfully",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Okay",
                confirmButtonColor: '#3085d6',
                closeOnCancel: true,
              }).then(result => {
                window.location.reload(false);
              });
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
              Swal.fire({
                title: "Error",
                text: err.response ? err.response.data.message : err.message,
                icon: "error",
                confirmButtonText: "Okay",
                confirmButtonColor: '#3085d6',
                closeOnCancel: true,
              });
            });
        }
      });
    } else {
      console.error(`Data for ID ${myidd} not found`);
    }
  }

  
  

  useEffect(() => {
    getsuppinfo();
    getsuppsummary();
    // getinteractionsummary();
  }, []);

    

      return (
     <div>
     <Header/>
     <div>
        <div className="toolbar py-3 py-lg-5" >
          <div className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                View Supplier
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card mb-5 mb-xl-8">    
                </div>
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
                  <div className="content flex-row-fluid" id="kt_content">
                    <a onClick={handleGoBack} className="btn btn-sm btn-danger">Go back</a>
                    {/* <a onClick={requestquote}  style={{float: 'right'}} className="btn btn-sm btn-info">Request Quote from Supplier</a> */}
                    <a onClick={requestlink}   style={{float: 'right', marginRight: '20px'}} id={org_info.id} className="btn btn-sm btn-primary">Send to Supplier to Update</a>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex flex-column flex-xl-row">
                      <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                        <div className="card mb-5 mb-xl-8">
                          <div className="card-body pt-15">
                            <div className="d-flex flex-center flex-column mb-5">
                            <div className="symbol symbol-100px symbol-circle mb-7">
                               {
                                  org_info.is_active==1?<button onClick={disable} className='btn btn-primary btn-sm' style={{ float: 'left', marginRight: '10px' }}>Disable Client</button> : <button onClick={enable} className='btn btn-primary btn-sm' style={{ float: 'left', marginRight: '10px' }}>Enable Client</button>
                                }
                              <button onClick={() => editorganization(org_info.id)} className='btn btn-info btn-sm' style={{ float: 'right', marginLeft: '10px' }}>Edit Info</button>
                              </div>

                              {/* <div className="symbol symbol-100px symbol-circle mb-7">
                                <img src="assets/media/svg/brand-logos/plurk.svg" alt=" company logo" style={{width: '50px'}} />
                              </div> */}
                              <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">
                              {org_info.suppliername}
                              </a>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                                {org_info.supplierlocation}
                              </div>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                               {org_info.business_cat}
                              </div>
                              <div className="d-flex flex-wrap flex-center">
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-75px">{int_info? int_info : 0}</span>
                                  </div>
                                  <div className="fw-semibold text-muted">Projects</div>
                                </div>
                              
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-50px">BBEEE Level</span>
                                  </div>
                                  <div className="fw-semibold text-muted">{org_info.supplierbbbee}</div>
                                </div>
                                
                              </div>
                            </div>
                            <div className="d-flex flex-stack fs-4 py-3">
                              <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_customer_view_details" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
                                Details
                               
                              </div>
                            </div>
                            <div className="separator separator-dashed my-3" />
                            <div id="kt_customer_view_details" className="collapse show">
                              <div className="py-5 fs-6">
                              {
                                  org_info.is_active==1?<span className="badge badge-light-info d-inline"> Active</span>: <span className="badge badge-light-danger d-inline"> Disabled</span>
                                }
                                <div className="fw-bold mt-5">Organization ID</div>
                                <div className="text-gray-600">{org_info.supplierregno}</div>
                                <div className="fw-bold mt-5">Official Email</div>
                                <div className="text-gray-600"><a href="#" className="text-gray-600 text-hover-primary">{org_info.contact_mail}</a></div>
                                <div className="fw-bold mt-5">Is Black Owned</div>
                                <div className="text-gray-600">{org_info.blackowned}</div>
                                <div className="fw-bold mt-5">Is Black Woman Owned</div>
                                <div className="text-gray-600">{org_info.blackwomanowned}</div>
                                <div className="fw-bold mt-5">Is designated Group</div>
                                <div className="text-gray-600">{org_info.designatedgroup}</div>
                                <div className="fw-bold mt-5">Company Type</div>
                                <div className="text-gray-600">{org_info.companytype}</div>
                                <div className="fw-bold mt-5">Official  Address</div>
                                <div className="text-gray-600">{org_info.contact_address}</div>
                                <div className="fw-bold mt-5"> City</div>
                                <div className="text-gray-600">{org_info.contact_city}</div>
                                <div className="fw-bold mt-5">Contact Person </div>
                                <div className="text-gray-600">{org_info.contact_name}</div>
                                <div className="fw-bold mt-5">Contact Person Email </div>
                                <div className="text-gray-600">{org_info.contact_mail}</div>
                                <div className="fw-bold mt-5">Contact Person Phone </div>
                                <div className="text-gray-600">{org_info.contact_phone}</div>
                                <div className="fw-bold mt-5">Contact Revenue Range </div>
                                <div className="text-gray-600">{org_info.revenue_range}</div>
                                <div className="fw-bold mt-5">VAT ID</div>
                                <div className="text-gray-600">{org_info.suppliervat}</div>
                                <div className="symbol symbol-100px symbol-circle mb-7">
                                {org_info.bbee_url ? (
                                  <a className='btn btn-primary btn-sm' target='_blank' href={org_info.bbee_url} style={{ float: 'left', marginRight: '10px' }}>View BBEEE Report</a>
                                ) : (
                                  <span>No BBEEE Report</span>
                                )}
                                {org_info.esg_url ? (
                                  <a className='btn btn-primary btn-sm' target='_blank' href={org_info.esg_url} style={{ float: 'left', marginRight: '10px' }}>View ESG Report</a>
                                ) : (
                                  <span>No ESG Report</span>
                                )}
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-lg-row-fluid ms-lg-15">
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
                            <div className="card pt-4 mb-6 mb-xl-9">
                              <div className="card-header border-0">
                                <div className="card-title">
                                  <h2>Completed Projects</h2>
                                  
                                </div>
                              </div>
                              <div className="card-body pt-0 pb-5">
                              <table className="table align-middle table-row-dashed gy-5" id="kt_table_customers_payment">
                              {summ_info == null ? <Spinner animation="border" /> : ""}
                               <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                              </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Viewsupplier;
