import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Viewstudent(){
    

      return (
     <div>
     <Header3/>
     <div>
        <div className="toolbar py-3 py-lg-5" >
          <div className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                View Student History
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card mb-5 mb-xl-8">    
                </div>
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
                  <div className="content flex-row-fluid" id="kt_content">
                    <a href="/studentlist" className="btn btn-sm btn-danger">Go back</a>
                    {/* <a href="/quote" style={{float: 'right'}} className="btn btn-sm btn-info">Request Quote from supplier</a> */}
                    <br />
                    <br />
                    <br />
                    <div className="d-flex flex-column flex-xl-row">
                      <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                        <div className="card mb-5 mb-xl-8">
                          <div className="card-body pt-15">
                            <div className="d-flex flex-center flex-column mb-5">
                              <a href="/quote" style={{float: 'right'}} className="btn btn-sm btn-info">Edit Student Info.</a>

                              <div className="symbol symbol-100px symbol-circle mb-7">
                                <img src="assets/media/svg/brand-logos/plurk.svg" alt=" company logo" style={{width: '50px'}} />
                              </div>
                              <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">
                              Reynolds Shany	
                              </a>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                                Johannesburg
                              </div>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                               ABC Consulting
                              </div>
                              <div className="d-flex flex-wrap flex-center">
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-75px">20</span>
                                  </div>
                                  <div className="fw-semibold text-muted"> Courses</div>
                                </div>
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-50px">88% </span>
                                  </div>
                                  <div className="fw-semibold text-muted">Overall Score</div>
                                </div>
                               
                                
                              </div>
                            </div>
                            <div className="d-flex flex-stack fs-4 py-3">
                              <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_customer_view_details" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
                                Details
                               
                              </div>
                            </div>
                            <div className="separator separator-dashed my-3" />
                            <div id="kt_customer_view_details" className="collapse show">
                              <div className="py-5 fs-6">
                                <div className="badge badge-light-info d-inline">Registered Student</div>
                                <div className="fw-bold mt-5">Organization name</div>
                                <div className="text-gray-600">ABC Consulting</div>
                                <div className="fw-bold mt-5">Official Email</div>
                                <div className="text-gray-600"><a href="#" className="text-gray-600 text-hover-primary">info@reynoldsshany.com</a></div>
                                <div className="fw-bold mt-5">ID Number</div>
                                <div className="text-gray-600">23902333</div>
                                <div className="fw-bold mt-5">EE Number</div>
                                <div className="text-gray-600">111658</div>
                                <div className="fw-bold mt-5">Coach </div>
                                <div className="text-gray-600">Lindy</div>
                                <div className="fw-bold mt-5">Race</div>
                                <div className="text-gray-600">African</div>
                                <div className="fw-bold mt-5">Phone</div>
                                <div className="text-gray-600">06301912888</div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-lg-row-fluid ms-lg-15">
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
                            <div className="card pt-4 mb-6 mb-xl-9">
                              <div className="card-header border-0">
                                <div className="card-title">
                                  <h2>Completed Courses</h2>
                                </div>
                              </div>
                              <div className="card-body pt-0 pb-5">
                                <table className="table align-middle table-row-dashed gy-5" id="kt_table_customers_payment">
                                  <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                                    <tr className="text-start text-muted text-uppercase gs-0">
                                    <th className="min-w-100px">Date</th>
                                      <th className="min-w-100px">Coach</th>
                                      <th className="min-w-100px">Course</th>
                                      <th className="min-w-100px">Cohort</th>
                                      <th>Score </th>
                                    </tr>
                                  </thead>
                                  <tbody className="fs-6 fw-semibold text-gray-600">
                                    <tr>
                                    <td>
                                        14 Dec 2023
                                        </td>
                                      <td>
                                        <a href="#" className="text-gray-600 text-hover-primary mb-1">Lindy</a>
                                      </td>
                                      <td>
                                        <span>Coaching 1 Orientation</span>
                                      </td>
                                      <td>
                                        <span>1</span>
                                      </td>
                                      <td>
                                        <span className="badge badge-light-success">100%</span>
                                      </td>
                                    </tr>
                                    <tr>
                                    <td>
                                        24 Dec 2023
                                        </td>
                                      <td>
                                        <a href="#" className="text-gray-600 text-hover-primary mb-1">Lindy</a>
                                      </td>
                                      <td>
                                        <span>Emotional Competence</span>
                                      </td>
                                      <td>
                                        <span> 1 </span>
                                      </td>
                                      <td>
                                        <span className="badge badge-light-warning">48%</span>
                                      </td>
                                    </tr>
                                    <tr>
                                    <td>
                                        28 Dec 2023
                                        </td>
                                      <td>
                                        <a href="#" className="text-gray-600 text-hover-primary mb-1">Lindy</a>
                                      </td>
                                      <td>
                                        <span>Service Excellence</span>
                                      </td>
                                      <td>
                                        <span> 1 </span>
                                      </td>
                                      <td>
                                        <span className="badge badge-light-danger">25%</span>
                                      </td>
                                    </tr>
                                     <tr>
                                    <td>
                                        28 Dec 2023
                                        </td>
                                      <td>
                                        <a href="#" className="text-gray-600 text-hover-primary mb-1">Maurice</a>
                                      </td>
                                      <td>
                                        <span>Stress and Wellbeing</span>
                                      </td>
                                      <td>
                                        <span> 1 </span>
                                      </td>
                                      <td>
                                        <span className="badge badge-warning">70%</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Viewstudent;
