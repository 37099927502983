import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component

function Studenthome(){
    const [state , setState] = useState({
        email: '',
        password: '',
    })

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }

      return (
              <div>
     <Header3/>
        
     <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
          <div id="kt_toolbar_container" className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                Dashboard
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="row g-5 g-lg-10">
              {/*begin::Col*/}
              <div className="col-xl-4 mb-xl-10">
                {/*begin::Mixed Widget 19*/}
                <div className="card h-md-100">
                  {/*begin::Beader*/}
                  <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">Recently Completed Cohorts</span>
                      <span className="text-muted fw-semibold fs-7">You have contacted 0 suppliers in total</span>
                    </h3>
                  </div>
                  <div className="card-body p-0 d-flex flex-column">
                    <div className="card-px pt-5 pb-10 flex-grow-1">
                      <div className="d-flex align-items-sm-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          
                        </div>
                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">                    
                          <div className="flex-grow-1 me-2">
                            <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">ABC Software Solutions</a>
                            <span className="text-muted fw-semibold d-block fs-7">2 Cohorts Completed</span>
                          </div>                     
                          <span className="badge badge-light fw-bold my-2">10th Jan 2024</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-sm-center mb-7">
                        <div className="symbol symbol-50px me-5">
                          
                        </div>
                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">                    
                          <div className="flex-grow-1 me-2">
                            <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">Super Manufacturing</a>
                            <span className="text-muted fw-semibold d-block fs-7">1 Cohort Completed</span>
                          </div>                     
                          <span className="badge badge-light fw-bold my-2">11th Jan 2024</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-sm-center ">
                        <div className="symbol symbol-50px me-5">
                         
                        </div>
                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">                    
                          <div className="flex-grow-1 me-2">
                            <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold">Cable International Ltd.</a>
                            <span className="text-muted fw-semibold d-block fs-7">3 Cohorts Completed</span>
                          </div>                     
                          <span className="badge badge-light fw-bold my-2">15th Jan 2024</span>
                        </div>
                      </div>
                    </div>
                    <div id="kt_charts_mixed_widget_19_chart" className="card-rounded-bottom" style={{height: '150px'}} />
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="row g-5 g-lg-10">
                  <div className="col-lg-6 mb-lg-10">  
                    <a href="#" className="card  h-150px" style={{backgroundColor: 'rgb(28 76 133)'}}>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-white fw-bold fs-1 mb-0 mt-5">
                            90
                          </div>
                          <div className="text-white fw-semibold fs-6">
                            Students
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6 mb-lg-10">  
                    <a href="#" className="card  h-150px" style={{backgroundColor: 'rgb(28 76 133)'}}>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-white fw-bold fs-1 mb-0 mt-5">
                            11
                          </div>
                          <div className="text-white fw-semibold fs-6">
                            Active Sessions
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                {/*begin::Row*/}
                <div className="row g-5 g-lg-10">
                  <div className="col-lg-6 mb-lg-10">  
                    <a href="#" className="card h-150px" style={{backgroundColor: 'rgb(28 76 133)'}}>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-white fw-bold fs-1 mb-0 mt-5">
                            210
                          </div>
                          <div className="text-white fw-semibold fs-6">
                            Students Created
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6 mb-lg-10">  
                    <a href="#" className="card h-150px" style={{backgroundColor: 'rgb(28 76 133)'}}>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-white fw-bold fs-1 mb-0 mt-5">
                            11
                          </div>
                          <div className="text-white fw-semibold fs-6">
                            Organizations
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <a href="#" className="card bgi-no-repeat h-150px mb-xl-10" style={{backgroundColor: '#1B283F', backgroundPosition: 'calc(100% + 1rem) bottom', backgroundSize: '25% auto', backgroundImage: 'url("assets/media/svg/humans/custom-1.svg")'}}>
                  <div className="card-body d-flex flex-column align-items-start justify-content-center">
                    <p className="text-white fs-7">
                      Access our wide directory of different suppliers. <br/>Create a search filter to suit your preference.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-xl-4 mb-xl-10">
                <div className="card bgi-no-repeat h-xl-100 mb-5 mb-lg-10" style={{backgroundColor: '#1B283F', backgroundPosition: '0 calc(100% + 0.5rem)', backgroundSize: '100% auto', backgroundImage: 'url("assets/media/svg/general/rhone.svg")'}}>
                  <div className="card-body">
                    <h3 className="text-white fw-bold my-7">View Study Report</h3>
                    <p className="text-muted fs-6 mb-7">
                      Click on the button below to create  <br/>  a report for your study. 
                    </p>
                    <a href="/studentreport"  className="btn btn-danger gw-bold px-6 py-3">Begin</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5 g-lg-10">
             
            </div>
            <br/>
           
          </div></div>




     <Footer/>
              </div>
               
        );
 
  };

export default Studenthome;
