import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import BusinessCategories from './BusinessCategories';
import Select from 'react-select';
import BASE_URL from './config';
import * as XLSX from 'xlsx';


function Uploadsupplier(){
  const MySwal = withReactContent(Swal);
  const uploadURL = BASE_URL+'/insertSuppliers';
  const orgURL = BASE_URL+'/fetchclients'
  const [org_info, setorg] = useState(['']);
  const [state , setState] = useState({
    client_id: '',
    file: ''
})

const downloadSampleFile = () => {
    const sampleFileUrl = 'assets/supplier_upload_sample_1.xlsx';
    const fileName = 'supplier_upload_sample_1.xlsx';
  
    // Create a hidden anchor element
    const link = document.createElement('a');
    link.href = sampleFileUrl;
    link.download = fileName;
  
    // Simulate a click on the anchor element
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  } 

const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};

const token = localStorage.getItem("ladpsouthafricaaccesstoken");
const headers2 = { 
  headers: {
    'AccessToken': token,
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const body = {
  'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
}


  const getorginfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      console.log(body)
      if(!response){
        setorg(0);
      console.log(body)
      }else{
        const sortedArray = response.data.data;
      console.log(body)
        console.log(sortedArray)
        setorg(sortedArray);
        setorg(sortedArray);
      }
    });
  };

  useEffect(() => {
    getorginfo();
  }, []);

  const clientoptions = org_info.map(org => ({
    value: org.id,
    label: org.clientname
  }));

  const handleSelectChange = (e) => {
    setState({
      ...state,
      file: e.target.files[0]
    });
  };

const blob = new Blob(['test'], { type: 'text/plain' });

// const file = new File([blob], 'test.txt', { type: 'text/plain' });


// const uploadSupplier = async (e) => {
//   e.preventDefault();
//   if (!state.client_id || !state.file) {
//     MySwal.fire({
//       title: <p>Please enter all required fields</p>,
//       didOpen: () => {
//       },
//     }).then(() => {
//     });
//   }else{
//     MySwal.showLoading()
//      const dashURL = BASE_URL+'/uploadSuppliers'
//      const postData = {
//         'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
//          'client_id': state.client_id,
//          'fileData': 'll'
//      }
//     const formData = new FormData();
//     formData.append('accesstoken', localStorage.getItem("ladpsouthafricaaccesstoken"));
//     formData.append('client_id', 1);
//     formData.append('fileData', file);
//     console.log(formData)
//     Swal.close()

//      MySwal.fire({
//          title: <p>please wait...</p>,
//          didOpen: () => {
//            MySwal.showLoading()
//            axios.post(dashURL, formData, headers2)
//          .then((response) => {
//            console.log("response received: ", response);
//            Swal.close()
//            Swal.fire({
//              title: "Successful",
//              text: "Batch Uploaded successfully",
//              icon: "success",
//              showCancelButton: false,
//              confirmButtonText: "Okay",
//              closeOnCancel: true,
//            }).then(result => {
//             window.location.reload(false);
//            })
//            })
//            .catch((err) => {
//            Swal.close()
//              console.log("AXIOS ERROR: ", err);
//              if(!err.response){
//              return MySwal.fire(err.message)
//              }else{
//              return MySwal.fire(err.response.data.message)
//              }
//            })
//          },
//        }).then(() => {
//        })
//  }
// };

const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file.');
      return;
    }
  
    const fileReader = new FileReader();
    fileReader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        console.log(JSON.stringify(jsonData));
        console.log(jsonData);
        const formbody = {
          'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
          'file': jsonData,
          'client_id': state.client_id,
        };
        MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading();
            axios.post(uploadURL, formbody, headers2)
              .then((response) => {
                console.log("response received: ", response);
                Swal.close();
                Swal.fire({
                  title: "Successful",
                  text: "File Uploaded Successfully",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                  confirmButtonColor: '#3085d6',
                  closeOnCancel: true,
                }).then(result => {
                  window.location.href= 'suppliers';
                });
              })
              .catch((err) => {
                Swal.close();
                console.error("AXIOS ERROR: ", err);
                if (!err.response) {
                  MySwal.fire(err.message);
                } else {
                  MySwal.fire(err.response.data.message);
                }
              });
          },
        });
      } catch (error) {
        console.error('Upload failed:', error);
      }
    };
  
    fileReader.readAsArrayBuffer(file);
  };
  


  // const handleUpload = async () => {
  //   if (!file) {
  //     alert('Please select a file.');
  //     return;
  //   }
  //   const fileReader = new FileReader();
  //   fileReader.onload = async (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const jsonData = XLSX.utils.sheet_to_json(sheet);
  //     console.log(jsonData);
  //     const body = {
  //       'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
  //       'file': jsonData,
  //       'client_id': localStorage.getItem("ladpsouthafricaaccesstoken"),
  //     }
  //     try {
  //       MySwal.fire({
  //                  title: <p>please wait...</p>,
  //                  didOpen: () => {
  //                    MySwal.showLoading()
  //                    axios.post(dashURL, formData, headers2)
  //                  .then((response) => {
  //                    console.log("response received: ", response);
  //                    Swal.close()
  //                    Swal.fire({
  //                      title: "Successful",
  //                      text: "File Uploaded successfully",
  //                      icon: "success",
  //                      showCancelButton: false,
  //                      confirmButtonText: "Okay",
  //                      closeOnCancel: true,
  //                    }).then(result => {
  //                     window.location.reload(false);
  //                    })
  //                    })
  //                    .catch((err) => {
  //                    Swal.close()
  //                      console.log("AXIOS ERROR: ", err);
  //                      if(!err.response){
  //                      return MySwal.fire(err.message)
  //                      }else{
  //                      return MySwal.fire(err.response.data.message)
  //                      }
  //                    })
  //                  },
  //                }).then(() => {
  //                })
  //     } catch (error) {
  //       console.error('Upload failed:', error);
  //     }
  //   };
  //   fileReader.readAsArrayBuffer(file);
  // };
      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
            <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Upload Suppliers</h3>
          </div>
          <div>
            <br/>
         
          <a  style={{float: 'right'}}  onClick={downloadSampleFile} className='btn btn-sm btn-success'>Download Sample Format for upload</a>
          </div>

        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-5'>
                <label className="required fw-semibold fs-6 mb-2">Select Client</label>
                <Select className="react-select-container" classNamePrefix="react-select"  value={clientoptions.find(option => option.value === state.client_id)} onChange={handleChange2('client_id')}
                    options={clientoptions}
                />
                </div>
                <div className='col-lg-5'>
                <label className="required fw-semibold fs-6 mb-2">Upload File</label>
                <input 
                    type="file" className="form-control mb-3 mb-lg-0" id="file" name="file"  onChange={handleFileChange}
                />
                </div>
               </div>
            </div>
           
          </form>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='suppliers'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button onClick={handleUpload} className="btn btn-info" id="kt_account_profile_details_submit">Upload</button>
            </div>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Uploadsupplier;
