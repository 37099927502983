import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import withReactContent from 'sweetalert2-react-content'
import BusinessCategories from './BusinessCategories';
import BBEEE from './bbeee';
import Select from 'react-select';
import BASE_URL from './config';
import axios from "axios";
import storage from "./firebaseConfig.js"
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";


function Editorganization(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState([]);
  const orgURL = BASE_URL+'/fetchclientsbyID'
  const orgupdateURL = BASE_URL+'/editOrganization'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const [state , setState] = useState({
    clientname: '',
    country: '',
    regno: '',
    vat: '',
    bbbee: '',
    esg: '',
    emp_no: '',
    businesscategory: '',
    contactperson: '',
    revenue: '',
    contactphone2: '',
    contactphone: '',
    contactaddress: '',
    contactcity: '',
    contactpaddress: '',
    contactsuburb: '',
    contactcity: '',
    esg_url: '',
    bbee_url: ''
})

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'client_id': myidd
  }

  const getorginfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(0);
      }else{
        const sortedArray = response.data.data[0];
        console.log(sortedArray)
        setorg(sortedArray);
        setorg(sortedArray);
      }
    });
  };

  useEffect(() => {
    getorginfo();
      // When org_info.firstname changes, update state.patientName
      setState((prevState) => ({
        ...prevState,
        clientname: org_info.clientname,
        country: org_info.clientlocation,
        regno: org_info.clientregno,
        vat: org_info.clientvat,
        bbbee: org_info.clientbbbee,
        email: org_info.clientemail,
        phone: org_info.client_phone,
        esg: org_info.esg,
        emp_no: org_info.emp_no,
        businesscategory: org_info.business_cat,
        contactperson: org_info.contact_name,
        revenue: org_info.revenue,
        contactphone: org_info.contact_phone,
        contactphone2: org_info.contact_phone_2,
        contactaddress: org_info.org_address,
        contactcity: org_info.org_city,
        email: org_info.org_mail,
        contactsuburb: org_info.org_suburb,
        contactpaddress: org_info.org_postal,
        // bbee_url: org_info.bbbeeurl,
        // esg_url: org_info.esgurl,
        
      }));
    }, [org_info.clientname]);

const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};

const handleFileChange = (event, inputName) => {
  const file = event.target.files[0];
  if (file) {
    setState({
      ...state,
      [inputName]: file, // Store the file object in state
    });
  }
};

const handleImageUpload = async (file) => {
  const storageRef = ref(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        // Get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    );
  });
};



const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 

const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const createOrg = async (e) => {
  e.preventDefault();
  if (!state.clientname || !state.country|| !state.regno|| !state.vat|| !state.bbbee|| !state.esg|| 
    !state.businesscategory|| !state.contactphone|| !state.contactperson|| !state.phone||  !state.email|| 
     !state.contactcity|| !state.contactaddress) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
     MySwal.showLoading()
     const esgreport = await handleImageUpload(state.esg_url);
     const bbbeereport = await handleImageUpload(state.bbee_url);
     console.log(state.bbee_url)
     console.log(esgreport)
     const postData = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
     'clientID': org_info.id,
     'clientname': state.clientname,
     'country': state.country,
     'regno': state.regno,
     'vat': state.vat,
     'bbbee': state.bbbee,
     'email': state.email,
     'phone': state.phone,
     'esg': state.esg,
     'emp_no': '20',
     'businesscategory': state.businesscategory,
     'contactperson': state.contactperson,
     'revenue': state.revenue,
     'contact_phone': state.contactphone,
     'contact_phone_2': state.contactphone2,
     'contactaddress': state.contactaddress,
     'contactcity': state.contactcity,
     'email': state.email,
     'contactsuburb': state.contactsuburb,
     'contactpaddress': state.contactpaddress,
     'bbbeeurl': bbbeereport,
     'esgurl': esgreport
     }
     console.log(postData);
      Swal.close()
     MySwal.fire({
         title: <p>please wait...</p>,
         confirmButtonColor: '#3085d6',
         didOpen: () => {
           MySwal.showLoading()
           axios.post(orgupdateURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Client Updated successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonColor: '#3085d6',
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
         window.location.reload(false);
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};




      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
            <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Edit Organization</h3>
          </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Name</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.clientname} id="clientname" name="clientname" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Organization Location</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={Countries}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Registration Number</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.regno} id="regno" name="regno" onChange={handleChange} />
                </div>
               </div>
              
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization VAT Number</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.vat} id="vat" name="vat" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization B-BBEE Level</label>
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.bbbee, label: state.bbbee }}  onChange={handleChange2('bbbee')}  
                           options={BBEEE}
                        />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Esg</label>
                  <select  aria-label="Select" value={state.esg} id="esg" name="esg" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Phone</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.phone} id="phone" name="phone" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Business Category</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.businesscategory, label: state.businesscategory }}  onChange={handleChange2('businesscategory')}  
                           options={BusinessCategories}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Contact Person</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactperson} id="contactperson" name="contactperson" onChange={handleChange} />
                </div>
               </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Contact Person Phone</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactphone} id="contactphone" name="contactphone" onChange={handleChange} />
                </div>
            
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Organization Contact Person Phone 2</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactphone2} id="contactphone2" name="contactphone2" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Email</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.email} id="email" name="email" onChange={handleChange} />
                </div>
              </div>
             
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Organization Suburb</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactsuburb} id="contactsuburb" name="contactsuburb" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization City </label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactcity} id="contactcity" name="contactcity" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Address</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactaddress} id="contactaddress" name="contactaddress" onChange={handleChange} />
                </div>
              </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Organization Postal Address </label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactpaddress} id="contactpaddress" name="contactpaddress" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload ESG Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="esg_url" name="esg_url" onChange={(event) => handleFileChange(event, 'esg_url')} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload BBBEE Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"   id="bbee_url" name="bbee_url" onChange={(event) => handleFileChange(event, 'bbee_url')} />
                </div>
                
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">View ESG Report</label>
                  <br/>
                  {org_info.esg_url ? (
                                  <a className='btn btn-primary btn-sm' target='_blank' href={org_info.esg_url} style={{ float: 'left', marginRight: '10px' }}>View ESG Report</a>
                                ) : (
                                  <span>No ESG Report Uploaded</span>
                                )}
                 </div>
              <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">Upload ESG Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="esg_url" name="esg_url" onChange={(event) => handleFileChange(event, 'esg_url')}/>
                </div>
              
                <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">View BBEEE Report</label>
                  <br/>
                {org_info.bbee_url ? (
                        <a className='btn btn-primary btn-sm' target='_blank' href={org_info.bbee_url} style={{ float: 'left', marginRight: '10px' }}>View BBEEE Report</a>
                    ) : (
                        <span>No BBEEE Report Uploaded</span>
                    )}
                 </div>
                 <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">Upload BBBEE Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="bbee_url" name="bbee_url" onChange={(event) => handleFileChange(event, 'bbee_url')}  />
                </div>
                </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='organizations'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button onClick={createOrg} className="btn btn-info" id="kt_account_profile_details_submit">Update Organization</button>
            </div>
          </form>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Editorganization;
