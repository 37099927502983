import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import CitiesAndProvincesSouthAfrica from './cities';
import BusinessCategories from './BusinessCategories';
import Select from 'react-select';
import BASE_URL from './config';
import storage from "./firebaseConfig.js"
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";


function Newsupplier(){
  const MySwal = withReactContent(Swal);
  const [org_info, setorg] = useState([]);
  const orgURL = BASE_URL+'/fetchclients'
  const [state , setState] = useState({
    name: '',
    country: '',
    regno: '',
    vat: '',
    bbbee: '',
    esg: '',
    emp_no: '',
    businesscategory: '',
    contactperson: '',
    revenue: '',
    contactphone2: '',
    contactphone: '',
    contactaddress: '',
    contactcity: '',
    contactemail: '',
    contactemail2: '',
    contactpaddress: '',
    contactsuburb: '',
    contactcity: '',
    supplierscol: '',
    bbee_url: '',
    esg_url: '',
    client_id: '',
    client_id2: '',
    client_id3: '',
    client_id4: '',
    client_id5: '',
    blackowned : '',
    blackwomanowned : '',
    designatedgroup : '',
    companytype : ''
})


const body = {
  'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
}

const getorginfo = async (e) => {
  axios.post(orgURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
      setorg(0);
    }else{
      const sortedArray = response.data.data;
      console.log(sortedArray)
      setorg(sortedArray);
      setorg(sortedArray);
    }
  });
};

useEffect(() => {
  getorginfo();
}, []);


const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};


const handleFileChange = (event, inputName) => {
  const file = event.target.files[0];
  if (file) {
    setState({
      ...state,
      [inputName]: file, // Store the file object in state
    });
  }
};

// const handleImageUpload = async (file) => {
//   const storageRef = ref(storage, `/files/${file.name}`);
//   const uploadTask = uploadBytesResumable(storageRef, file);

//   return new Promise((resolve, reject) => {
//     uploadTask.on(
//       "state_changed",
//       (snapshot) => {
//         const percent = Math.round(
//           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//         );
//       },
//       (err) => {
//         console.log(err);
//         reject(err);
//       },
//       () => {
//         // Get the download URL
//         getDownloadURL(uploadTask.snapshot.ref)
//           .then((url) => {
//             resolve(url);
//           })
//           .catch((error) => {
//             console.log(error);
//             reject(error);
//           });
//       }
//     );
//   });
// };

const handleImageUpload = async (file) => {
  // Check if file exists
  if (!file) {
    return Promise.resolve(null); // Resolve with null if file is empty
  }

  const storageRef = ref(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        // Get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    );
  });
};




const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 

const uploadsupplier = (e) => {
  window.location.href='uploadsupplier'
}


const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const createSupplier = async (e) => {
  e.preventDefault();
  if (!state.name || !state.country|| !state.regno|| !state.vat|| !state.bbbee|| !state.esg|| !state.blackowned ||!state.blackwomanowned|| !state.emp_no|| !state.businesscategory|| !state.contactperson|| !state.revenue|| !state.contactphone|| !state.contactaddress|| !state.contactcity|| !state.contactemail) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
    MySwal.showLoading()
     const dashURL = BASE_URL+'/createsupplier'
     const esgreport = state.esg_url?await handleImageUpload(state.esg_url): '';
     const bbbeereport = state.bbee_url? await handleImageUpload(state.bbee_url): '';
     const postData = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
     'suppliername': state.name,
     'supplierlocation': state.country,
     'supplierregno': state.regno,
     'suppliervat': state.vat,
     'supplierbbbee': state.bbbee,
     'supplieresg': state.esg,
     'no_of_employees': state.emp_no,
     'business_cat': state.businesscategory,
     'revenue_range': state.revenue,
     'contact_name': state.contactperson,
     'contact_phone': state.contactphone,
     'contact_phone_2': state.contactphone2,
     'contact_mail': state.contactemail,
     'contact_mail_2': state.contactemail2,
     'contact_suburb': state.contactsuburb,
     'contact_city': state.contactcity,
     'contact_address': state.contactaddress,
     'contact_postal': state.contactpaddress,
     'supplierscol': '2',
     'bbee_url': bbbeereport,
     'esg_url': esgreport,
     'client_id': state.client_id,
     'client_id2': state.client_id2,
     'client_id3': state.client_id3,
     'client_id4': state.client_id4,
     'client_id5': state.client_id5,
     'blackowned': state.blackowned,
    'blackwomanowned': state.blackwomanowned,
    'designatedgroup': state.designatedgroup,
    'companytype': state.companytype
     }

     console.log(postData);
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Supplier Created successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
         window.location.href = 'suppliers';
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};
      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Add New Supplier</span>
                </h3>
                <div className="card-toolbar">
                 <button style={{float: 'right'}} onClick={uploadsupplier}  className="btn btn-success btn-active-light-success me-2">Upload File</button>
                 </div>
              </div>
              <div className="card-body py-3">
              <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Name</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.name} id="name" name="name" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Supplier Location</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={Countries}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Registration Number</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0" value={state.regno} id="regno" name="regno" onChange={handleChange} />
                </div>
               </div>
              
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier VAT Number</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.vat} id="vat" name="vat" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier B-BBEE Level</label>
                  <select  aria-label="Select"  value={state.bbbee} id="bbbee" name="bbbee" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="LEVEL 1">LEVEL 1</option>
                    <option  value="LEVEL 2">LEVEL 2</option>
                    <option value="LEVEL 3">LEVEL 3</option>
                    <option  value="LEVEL 4">LEVEL 4</option>
                    <option value="LEVEL 5">LEVEL 5</option>
                    <option  value="LEVEL 6">LEVEL 6</option>
                    <option  value="LEVEL 7">LEVEL 7</option>
                    <option  value="LEVEL 8">LEVEL 8</option>
                    <option  value="NON COMPLIANCE">NON COMPLIANT </option>
                    <option  value="NON COMPLIANCE">NONE </option>
                  </select>
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier ESG Report</label>
                  <select  aria-label="Select"  value={state.esg} id="esg" name="esg" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
              </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Is 51% Black Owned</label>
                  <select  aria-label="Select"  value={state.blackowned} id="blackowned" name="blackowned" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Is 30% Black Woman Owned</label>
                  <select  aria-label="Select"  value={state.blackwomanowned} id="blackwomanowned" name="blackwomanowned" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Is 51% Designated Group Company </label>
                  <select  aria-label="Select"  value={state.designatedgroup} id="designatedgroup" name="designatedgroup" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">No of Employees</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.emp_no} id="emp_no" name="emp_no" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Service Category</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.businesscategory, label: state.businesscategory }}  onChange={handleChange2('businesscategory')}  
                           options={BusinessCategories}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Annual Revenue Range</label>
                  <select  aria-label="Select"  value={state.revenue} id="revenue" name="revenue" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="R10, 000-R50,000">R10,000 - R50,000</option>
                    <option  value="R50,000 - R100,000">R50,000 - R100,000</option>
                    <option value="R100,000 - R500,000">R100,000 - R500,000</option>
                    <option  value="R500,000 - R1,000,000">R500,000 - R1,000,000</option>
                    <option value="R1,000,000 - R5,000,000">R1,000,000 - R5,000,000</option>
                    <option  value="R5,000,000 - R10,000,000">R5,000,000 - R10,000,000</option>
                    <option  value="R10,000,000 - R50,000,000">R10,000,000 - R50,000,000</option>
                    <option  value="R50,000,000 -">R50,000,000 - </option>
                  </select>
                </div>
               </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Person</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactperson} id="contactperson" name="contactperson" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Person Phone</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactphone} id="contactphone" name="contactphone" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Person Phone 2</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactphone2} id="contactphone2" name="contactphone2" onChange={handleChange} />
                </div>
              </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Email</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactemail} id="contactemail" name="contactemail" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Email 2</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactemail2} id="contactemail2" name="contactemail2" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Suburb</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactsuburb} id="contactsuburb" name="contactsuburb" onChange={handleChange} />
                </div>
              </div>
              
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact City </label>
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.contactcity, label: state.contactcity }}  onChange={handleChange2('contactcity')}  
                           options={CitiesAndProvincesSouthAfrica}
                        />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Address</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactaddress} id="contactaddress" name="contactaddress" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Postal Address </label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactpaddress} id="contactpaddress" name="contactpaddress" onChange={handleChange} />
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Business Type</label>
                  <select  aria-label="Select"  value={state.companytype} id="companytype" name="companytype" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="Exempted Micro Enterprise">Exempted Micro Enterprise (EME)</option>
                    <option  value="Qualifying small Enterprise">Qualifying small Enterprise (QSE)</option>
                    <option  value="Generic Company">Generic Company (GNC)</option>
                  </select>
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload ESG Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="esg_url" name="esg_url" onChange={(event) => handleFileChange(event, 'esg_url')}/>
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload BBBEE Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="bbee_url" name="bbee_url" onChange={(event) => handleFileChange(event, 'bbee_url')}  />
                </div>
                </div>
                
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Assign to Client</label>
                  <select aria-label="Select" value={state.client_id} id="client_id" name="client_id" onChange={handleChange} className="form-select form-select-solid form-select-lg">
                    <option value="">Select...</option>
                    {org_info.map((org, index) => (
                      <option key={index} value={org.id}>{org.clientname}</option>
                    ))}
                  </select>
                </div>
                    <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2">Assign another Client</label>
                        <select aria-label="Select" value={state.client_id2} id="client_id2" name="client_id2" onChange={handleChange} className="form-select form-select-solid form-select-lg">
                          <option value="">Select...</option>
                          {org_info.map((org, index) => (
                            <option key={index} value={org.id}>{org.clientname}</option>
                          ))}
                        </select>
                      </div>
                </div>
               
              {/* </div> */}
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='suppliers'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button onClick={createSupplier} className="btn btn-info" id="kt_account_profile_details_submit">Send Request</button>
            </div>
          </form>
              </div>
            </div>
          </div>
          </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Newsupplier;
