import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import Select from 'react-select';
import BASE_URL from './config';


function Viewquote(){
  const MySwal = withReactContent(Swal);
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [org_info, setorg] = useState(['']);
  const linkURL = BASE_URL+'/purchase_order'
  const history = useHistory();
  const [quote_info, setquote] = useState(['']);
  const orgURL = BASE_URL+'/fetchquotesbyID'
  const updatequotereviewURL = BASE_URL+'/updatequotereview'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const handleGoBack = () => {
    history.goBack();
  };
  const [state , setState] = useState({
    projtitle: '',
    review: '',
    country: '',
    timeline: '',
    title: '',
    title1: '',
    title2: '',
    title3: '',
    title4: '',
    title5: '',
    title6: '',
    title7: '',
    title8: '',
    title9: '',
    desc: '',
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    desc7: '',
    desc8: '',
    desc9: '',
    qty: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
    qty6: '',
    qty7: '',
    qty8: '',
    qty9: '',
    price: '',
    price1: '',
    price2: '',
    price3: '',
    price4: '',
    price5: '',
    price6: '',
    price7: '',
    price8: '',
    price9: '',
    tot: '',
    tot1: '',
    tot2: '',
    tot3: '',
    tot4: '',
    tot5: '',
    tot6: '',
    tot7: '',
    tot8: '',
    tot9: '',
})

const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'quote_id': myidd,
    'review': state.review
  }

  const linkbody = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'supplier_id': myidd,
    'supplier_name': quote_info.supplier_name,
    'supplier_email': quote_info.supplier_email,
  }

  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  } 

  const purchase_order = (e) => {
    console.log(myidd)
    if (quote_info.status=='Supplier has Updated') {
      MySwal.fire({
        text: 'Send Purchase Order to Supplier?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
      }).then((result) => {
        console.log(linkbody)
        if (result.isConfirmed) {
          MySwal.showLoading();
          axios.post(linkURL, linkbody, headers2)
            .then((response) => {
              console.log("response received: ", response);
              Swal.fire({
                title: "Successful",
                text: "Purchase Order Sent successfully",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Okay",
                confirmButtonColor: '#3085d6',
                closeOnCancel: true,
              }).then(result => {
                window.location.reload(false);
              });
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
              Swal.fire({
                title: "Error",
                text: err.response ? err.response.data.message : err.message,
                icon: "error",
                confirmButtonText: "Okay",
                confirmButtonColor: '#3085d6',
                closeOnCancel: true,
              });
            });
        }
      });
    } else {
      MySwal.fire({
        text: 'Supplier is yet to update quote',
        icon: 'alert',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
      }).then(() => {
    })
  }
}


const getquoteinfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setquote(0);
      }else{
        const sortedArray = response.data.data[0];
        console.log(sortedArray)
        setquote(sortedArray);
        setquote(sortedArray);
      }
    });
  };

  const addreview = async (e) => {
    MySwal.fire({
      title: 'Add a review for the work done',
      html: (
        <select id="review" name="review" onChange={handleChange} className="swal2-select">
          <option value="">--select--</option>
          <option value="Excellent">Excellent</option>
          <option value="Very Satisfied">Very Satisfied</option>
          <option value="Satisfied">Satisfied</option>
          <option value="Poor">Poor</option>
          <option value="Very Poor">Very Poor</option>
        </select>
      ),
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#3085d6',
      preConfirm: () => {
        const selectedOption = document.getElementById('review').value;
        if (!selectedOption) {
          Swal.showValidationMessage('You need to select an option');
        }
        return selectedOption;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const quotebody = {
          'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
          'quote_id': myidd,
          'review': result.value, // Accessing the selected value from result.value
        };
        console.log(quotebody);
        axios.post(updatequotereviewURL, quotebody, headers2)
          .then((response) => {
            console.log(response);
            Swal.fire('Success!', 'Your submission has been updated.', 'success');
          })
          .catch((error) => {
            console.log(error);
            Swal.fire('Error!', 'There was an error processing your request.', 'error');
          });
      }
    });
  };


  

   

    const allquotes = (e) => {
      window.location.href='/allquotes'
    }

    useEffect(() => {
        getquoteinfo();
          setState((prevState) => ({
            ...prevState,
            projtitle: quote_info.projtitle,
            timeline: quote_info.timeline,
            country: quote_info.country,
            title: quote_info.title,
            title1: quote_info.title1,
            title2: quote_info.title2,
            title3: quote_info.title3,
            title4: quote_info.title4,
            title5: quote_info.title5,
            title6: quote_info.title6,
            title7: quote_info.title7,
            title8: quote_info.title8,
            title9: quote_info.title9,
            desc: quote_info.description,
            desc1: quote_info.description1,
            desc2: quote_info.description2,
            desc3: quote_info.description3,
            desc4: quote_info.description4,
            desc5: quote_info.description5,
            desc6: quote_info.description6,
            desc7: quote_info.description7,
            desc8: quote_info.description8,
            desc9: quote_info.description9,
            price: quote_info.price,
            price1: quote_info.price1,
            price2: quote_info.price2,
            price3: quote_info.price3,
            price4: quote_info.price4,
            price5: quote_info.price5,
            price6: quote_info.price6,
            price7: quote_info.price7,
            price8: quote_info.price8,
            price9: quote_info.price9,
            qty: quote_info.qty,
            qty1: quote_info.qty1,
            qty2: quote_info.qty2,
            qty3: quote_info.qty3,
            qty4: quote_info.qty4,
            qty5: quote_info.qty5,
            qty6: quote_info.qty6,
            qty7: quote_info.qty7,
            qty8: quote_info.qty8,
            qty9: quote_info.qty9,
            tot:  quote_info.tot,
            tot1: quote_info.tot1,
            tot2: quote_info.tot2,
            tot3: quote_info.tot3,
            tot4: quote_info.tot4,
            tot5: quote_info.tot5,
            tot6: quote_info.tot6,
            tot7: quote_info.tot7,
            tot8: quote_info.tot8,
            tot9: quote_info.tot9,
          }));
        }, [quote_info.projtitle]);

    const handleChange2 = (fieldName) => (selectedOption) => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value
      }));
    };

    const token = localStorage.getItem('ladpsouthafricaaccesstoken');
      const headers2 = { 
        headers: {
          'AccessToken': token,
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': "*",
        }
      };



      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <br/>
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
        <a onClick={handleGoBack} className="btn btn-sm btn-danger">Go back</a>
        <br/>
        <br/>
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">View Quote Requested</h3> 
          </div>

          <div className="card-toolbar">
            {/* {quote_info.status&&quote_info.status=='Supplier has Updated'?
           <a onClick={addreview} className="btn btn-sm btn-info">Add Review</a>
            :''  }          */}
          <span class="badge badge-primary fw-semibold fs-9 px-2 ms-2 cursor-default ms-2"><h6>{quote_info.status}</h6></span>
                 </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Project Title</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.projtitle} id="projtitle" name="projtitle" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Estimated Delivery Timeline</label>
                  <input type="date" disabled className="form-control  mb-3 mb-lg-0" value={state.timeline} id="timeline" name="timeline" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Project Location</label>
                <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.country} id="country" name="country" onChange={handleChange} />

                {/* <Select  className="react-select-container" disabled  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={Countries}
                        /> */}
               </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br/>

      <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <div className="card-title m-0 d-flex justify-content-between align-items-center">
          <h3 className="fw-bold m-0">Enter Line Items</h3>
          {/* <button style={{marginLeft: '100px'}} className='btn btn-success btn-sm' onClick={() => setShowMoreFields(!showMoreFields)}>
            {showMoreFields ? 'Hide More Fields' : 'Show More Fields'}
          </button> */}
        </div>
        
        </div>
       
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">Item Title</label>
                  <input type="text" disabled className="form-control disabled  mb-3 mb-lg-0"  value={state.title} id="title" name="title" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">Item Description</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc} id="desc" name="desc" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className="required fw-semibold fs-6 mb-2">Item Quantity</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty} id="qty" name="qty" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className="required fw-semibold fs-6 mb-2">Unit Price</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price} id="price" name="price" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className="required fw-semibold fs-6 mb-2">Total Amount</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot} id="tot" name="tot" onChange={handleChange} />
                </div>
              </div>
              {quote_info.title1? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title1} id="title1" name="title1" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc1} id="desc1" name="desc1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty1} id="qty1" name="qty1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price1} id="price1" name="price1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot1} id="tot1" name="tot1" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title2? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title2} id="title2" name="title2" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc2} id="desc2" name="desc2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty2} id="qty2" name="qty2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price2} id="price2" name="price2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot2} id="tot2" name="tot2" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title3? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title3} id="title3" name="title3" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc3} id="desc3" name="desc3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty3} id="qty3" name="qty3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price3} id="price3" name="price3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot3} id="tot3" name="tot3" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title4? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title4} id="title4" name="title4" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc4} id="desc4" name="desc4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty4} id="qty4" name="qty4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price4} id="price4" name="price4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot4} id="tot4" name="tot4" onChange={handleChange} />
                </div>
              </div>
              : ''}
             
             {quote_info.title5? 
            //   <div id='morefields'>
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title5} id="title5" name="title5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc5} id="desc5" name="desc5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty5} id="qty5" name="qty5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price5} id="price5" name="price5" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot5} id="tot5" name="tot5" onChange={handleChange} />
                </div>
                </div>
              : ''}
             {quote_info.title6? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title6} id="title" name="title" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc6} id="desc6" name="desc6" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty6} id="qty6" name="qty6" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price6} id="price6" name="price6" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot6} id="tot6" name="tot6" onChange={handleChange} />
                </div>
                </div>
              : ''}

             {quote_info.title7? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title7} id="title7" name="title7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc7} id="desc7" name="desc7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty7} id="qty7" name="qty7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price7} id="price7" name="price7" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot7} id="tot7" name="tot7" onChange={handleChange} />
                </div>
                </div>
              : ''}

             {quote_info.title8? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title8} id="title8" name="title8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc8} id="desc8" name="desc8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty8} id="qty8" name="qty8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price8} id="price8" name="price8" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot8} id="tot8" name="tot8" onChange={handleChange} />
                </div>
                </div>
              : ''}

            {quote_info.title9? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title9} id="title9" name="title9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc9} id="desc9" name="desc9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty9} id="qty9" name="qty9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price9} id="price9" name="price9" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot9} id="tot9" name="tot9" onChange={handleChange} />
                </div>
                </div>
              : ''}
         

           
            </div>
          </form>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
              {/* <a href='viewsupplier'  className="btn btn-light btn-active-light-primary me-2">Discard</a> */}
              <button onClick={purchase_order}  className="btn btn-info" >Send Purchase Order</button>
            </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Viewquote;
