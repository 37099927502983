const BusinessCategories = [
    { value: null, label: '--Select--' },
    { value: "Accounting", label: "Accounting" },
    { value: "Advertising", label: "Advertising" },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Automotive", label: "Automotive" },
    { value: "Banking", label: "Banking" },
    { value: "Construction", label: "Construction" },
    { value: "Consulting", label: "Consulting" },
    { value: "Education", label: "Education" },
    { value: "Engineering", label: "Engineering" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Fashion", label: "Fashion" },
    { value: "Finance", label: "Finance" },
    { value: "Food and Beverage", label: "Food and Beverage" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Insurance", label: "Insurance" },
    { value: "Legal", label: "Legal" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Marketing", label: "Marketing" },
    { value: "Media", label: "Media" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Retail", label: "Retail" },
    { value: "Telecommunications", label: "Telecommunications" },
    { value: "Transportation", label: "Transportation" },
    { value: "Travel", label: "Travel" },
    { value: "Beauty", label: "Beauty" },
    { value: "Fitness", label: "Fitness" },
    { value: "Sports", label: "Sports" },
    { value: "E-commerce", label: "E-commerce" },
    { value: "Technology", label: "Technology" },
    { value: "Art and Design", label: "Art and Design" },
    { value: "Music", label: "Music" },
    { value: "Photography", label: "Photography" },
    { value: "Gaming", label: "Gaming" },
    { value: "Pets", label: "Pets" },
    { value: "Travel and Tourism", label: "Travel and Tourism" },
    { value: "Event Planning", label: "Event Planning" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Environmental", label: "Environmental" },
    { value: "Non-profit", label: "Non-profit" },
    { value: "Consulting", label: "Consulting" },
    { value: "Fashion and Apparel", label: "Fashion and Apparel" },
    { value: "Health and Wellness", label: "Health and Wellness" },
    { value: "Food Service", label: "Food Service" },
    { value: "Social Media", label: "Social Media" },
    { value: "Legal Services", label: "Legal Services" },
    { value: "Financial Services", label: "Financial Services" },
    { value: "Education and Training", label: "Education and Training" },
    ]

export default BusinessCategories;