import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header2 from './header2'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import BusinessCategories from './BusinessCategories';
import CitiesAndProvincesSouthAfrica from './cities';
import Select from 'react-select';
import BASE_URL from './config';


function Orgnewuote(){
  const MySwal = withReactContent(Swal);
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [org_info, setorg] = useState(['']);
  const orgURL = BASE_URL+'/fetchSupplierbyID'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const crypto = require('crypto');
  function generateToken(id) {
    const randomToken = crypto.randomBytes(20).toString('hex');
    return `${id}_${randomToken}`;
  }
  const [state , setState] = useState({
    projtitle: '',
    country: '',
    timeline: '',
    title: '',
    title1: '',
    title2: '',
    title3: '',
    title4: '',
    title5: '',
    title6: '',
    title7: '',
    title8: '',
    title9: '',
    desc: '',
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    desc7: '',
    desc8: '',
    desc9: '',
    qty: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
    qty6: '',
    qty7: '',
    qty8: '',
    qty9: '',
})

const getsuppinfo = async (e) => {
  axios.post(orgURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
      setorg(0);
    }else{
      const sortedArray = response.data.data[0];
      console.log(sortedArray)
      setorg(sortedArray);
      setorg(sortedArray);
    }
  });
};



    const body = {
      'accesstoken': localStorage.getItem("ladpsouthafricaaccessorgtoken"),
      'supplier_id': myidd
    }


    const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
    } 

    const allquotes = (e) => {
      window.location.href='/allquotes'
    }


  useEffect(() => {
    getsuppinfo();
  }, []);

    const handleChange2 = (fieldName) => (selectedOption) => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value
      }));
    };

    const token = localStorage.getItem('sl_med_tokenize');
      const headers2 = { 
        headers: {
          'AccessToken': JSON.parse(token),
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': "*",
        }
      };

      const createquote = async (e) => {
        e.preventDefault();
        if (!state.projtitle || !state.country|| !state.timeline|| !state.title|| !state.desc|| !state.qty) {
          MySwal.fire({
            title: <p>Please enter all required fields</p>,
            didOpen: () => {
            },
          }).then(() => {
          });
        }else{
          MySwal.showLoading()
           const dashURL = BASE_URL+'/quote_requests'
           const postData = {
          'accesstoken': localStorage.getItem("ladpsouthafricaaccessorgtoken"),
          'client_id': localStorage.getItem("ladpsouthafricauerorgid"),
          'supplier_id': myidd,
          'supplier_email': org_info.contact_mail,
          'supplier_name': org_info.suppliername,
           'projtitle': state.projtitle,
           'title': state.title,
           'country': state.country,
           'timeline': state.timeline,
           'title': state.title,
           'desc': state.desc,
           'qty': state.qty,
           'title1': state.title1,
           'title2': state.title2,
           'title3': state.title3,
           'title4': state.title4,
           'title5': state.title5,
           'title6': state.title6,
           'title7': state.title7,
           'title8': state.title8,
           'title9': state.title9,
           'desc1': state.desc1,
           'desc2': state.desc2,
           'desc3': state.desc3,
           'desc4': state.desc4,
           'desc5': state.desc5,
           'desc6': state.desc6,
           'desc7': state.desc7,
           'desc8': state.desc8,
           'desc9': state.desc9,
           'qty1': state.qty1,
           'qty2': state.qty2,
           'qty3': state.qty3,
           'qty4': state.qty4,
           'qty5': state.qty5,
           'qty6': state.qty6,
           'qty7': state.qty7,
           'qty8': state.qty8,
           'qty9': state.qty9,
           'status': 'Sent to Supplier'
           }
           console.log(postData);
           MySwal.fire({
               title: <p>please wait...</p>,
               didOpen: () => {
                 MySwal.showLoading()
                 axios.post(dashURL, postData, headers2)
               .then((response) => {
                 console.log("response received: ", response);
                 Swal.close()
                 Swal.fire({
                   title: "Successful",
                   text: "Quote Sent to Supplier successfully",
                   icon: "success",
                   showCancelButton: false,
                   confirmButtonColor: '#3085d6',
                   confirmButtonText: "Okay",
                   closeOnCancel: true,
                 }).then(result => {
               window.location.href = 'orgquotes';
                 })
                 })
                 .catch((err) => {
                 Swal.close()
                   console.log("AXIOS ERROR: ", err);
                   if(!err.response){
                   return MySwal.fire(err.message)
                   }else{
                   return MySwal.fire(err.response.data.message)
                   }
                 })
               },
             }).then(() => {
             })
       }
      };


      return (
     <div>
     <Header2/>
     <br/>
     <br/>
     <br/>
     <br/>
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Request Quote from Supplier</h3>
          </div>
          <div className="card-toolbar">
                 <button style={{float: 'right'}} onClick={allquotes} className="btn btn-info btn-active-light-info me-2">View all Quotes</button>
                 </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Project Title</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.projtitle} id="projtitle" name="projtitle" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Estimated Delivery Timeline</label>
                  <input type="date" className="form-control  mb-3 mb-lg-0" value={state.timeline} id="timeline" name="timeline" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Project Location</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={CitiesAndProvincesSouthAfrica}
                        />
               </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br/>

      <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <div className="card-title m-0 d-flex justify-content-between align-items-center">
          <h3 className="fw-bold m-0">Enter Line Items</h3>
          <button style={{marginLeft: '100px'}} className='btn btn-success btn-sm' onClick={() => setShowMoreFields(!showMoreFields)}>
            {showMoreFields ? 'Hide More Fields' : 'Show More Fields'}
          </button>
        </div>
        
        </div>
       
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Item Title</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title} id="title" name="title" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Item Description</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc} id="desc" name="desc" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Item Quantity</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty} id="qty" name="qty" onChange={handleChange} />
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title1} id="title1" name="title1" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc1} id="desc1" name="desc1" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty1} id="qty1" name="qty1" onChange={handleChange} />
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title2} id="title2" name="title2" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc2} id="desc2" name="desc2" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty2} id="qty2" name="qty2" onChange={handleChange} />
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title3} id="title3" name="title3" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc3} id="desc3" name="desc3" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty3} id="qty3" name="qty3" onChange={handleChange} />
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title4} id="title4" name="title4" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc4} id="desc4" name="desc4" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty4} id="qty4" name="qty4" onChange={handleChange} />
                </div>
              </div>
             
            {showMoreFields && (
              <div id='morefields'>
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title5} id="title5" name="title5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc5} id="desc5" name="desc5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty5} id="qty5" name="qty5" onChange={handleChange} />
                  </div>
                </div>
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title6} id="title" name="title" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc6} id="desc6" name="desc6" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty6} id="qty6" name="qty6" onChange={handleChange} />
                  </div>
                </div>
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title7} id="title7" name="title7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc7} id="desc7" name="desc7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty7} id="qty7" name="qty7" onChange={handleChange} />
                  </div>
                </div>
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title8} id="title8" name="title8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc8} id="desc8" name="desc8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty8} id="qty8" name="qty8" onChange={handleChange} />
                  </div>
                </div>
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title9} id="title9" name="title9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc9} id="desc9" name="desc9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-4'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty9} id="qty9" name="qty9" onChange={handleChange} />
                  </div>
                </div>
            </div>
              )}

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              {/* <a href='viewsupplier'  className="btn btn-light btn-active-light-primary me-2">Discard</a> */}
              <button onClick={createquote} className="btn btn-info" id="kt_account_profile_details_submit">Submit</button>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
 </div>
</div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Orgnewuote;
