import React, {useState,useEffect} from 'react';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import BASE_URL from './config';



function Launch(){
const history = useHistory();
const MySwal = withReactContent(Swal)
const [state , setState] = useState({
  loginemail: '',
  loginpassword: ''
})
const [passtype, setpasstype] = useState('password');
const hideme = (e) => {
  e.preventDefault(); 
  setpasstype('text')
}

const loginUrl = BASE_URL+'/login';
// const loginUrl = "https://justgymbackend.starlightsoftware.co.bw/api/login";
const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}
var postloginData = {
  "email": state.loginemail,
  "password": state.loginpassword,
};

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const adminlogin = (e) => {
    history.push('adminsignin')
  }

  const clientlogin = (e) => {
    history.push('signin')
  }

      return (
     <div>
      <div className="d-flex flex-column flex-root" style={{backgroundImage: 'url(assets/media/bglogin.jpg)', backgroundSize: 'cover'}}>
          <div className="d-flex flex-column flex-column-fluid flex-lg-row">
            <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">              
              <div className="d-flex flex-center flex-lg-start flex-column">              
                <center>
                <img alt="Logo" src="assets/media/bglogo.png" style={{width:'20%'}} />
                <br/>
                  <br/>
                  <br/>
                  <br/>
                  <h1 style={{color: 'white'}}>BIG IMPACT GROUP</h1>
                  <h2 style={{color: 'white'}}>Suppliers Procurement Hub</h2>
                <h3 className="text-white fw-normal m-0"> 
                Empowering Suppliers, Enhancing Efficiency!
                </h3>  
                </center>
                 
              </div>
            </div>
          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
              <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="/metronic8/demo16/../demo16/index.html" action="#">
                  <div className="text-center mb-11">
                    <h4 className="text-grayfw-bolder mb-3">
                      Click to Signin as an Admin or as an Organization
                    </h4>
                   
                  </div>
                  <br/>
                  <br/>
                  <div className='row'>
                    <div className='col-lg-6'>
                    <img alt="Logo" src="assets/media/34.png" style={{width:'100%'}} />
                   <center>
                   <button onClick={adminlogin}  className="btn btn-info">
                     Sign in as Admin
                    </button>
                   </center>
                    </div>
                    <div className='col-lg-6'>
                    <img alt="Logo" src="assets/media/34.png" style={{width:'100%'}} />
                   <center>
                   <button onClick={clientlogin}  className="btn btn-info">
                      Sign in as Client
                    </button>
                   </center>
                    </div>
                  </div>
                 
                  <br/>
                  <br/>
                  <br/>
                  {/* <div className="d-grid mb-10">
                    <button onClick={handleLoginSubmit}  className="btn btn-info">
                      <span className="indicator-label">
                        Sign In</span>
                    </button>
                  </div> */}
                 
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                  </div>
                 
                </form>
                
              </div>
              
            </div>
           
          </div>
          <br/>
                 
        </div>
        <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                 
      </div>
   </div>
               
        );
 
  };

export default Launch;
