import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import Select from 'react-select';
import BASE_URL from './config';


const Editquote  = ({ match }) => {
  const { idoo } = match.params;
  const MySwal = withReactContent(Swal);
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [org_info, setorg] = useState(['']);
  const [quote_info, setquote] = useState(['']);
  const orgURL = BASE_URL+'/fetchquotesbyID'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const [state , setState] = useState({
    projtitle: '',
    country: '',
    timeline: '',
    title: '',
    title1: '',
    title2: '',
    title3: '',
    title4: '',
    title5: '',
    title6: '',
    title7: '',
    title8: '',
    title9: '',
    desc: '',
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    desc7: '',
    desc8: '',
    desc9: '',
    qty: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
    qty6: '',
    qty7: '',
    qty8: '',
    qty9: '',
    price: '',
    price1: '',
    price2: '',
    price3: '',
    price4: '',
    price5: '',
    price6: '',
    price7: '',
    price8: '',
    price9: '',
    tot: '',
    tot1: '',
    tot2: '',
    tot3: '',
    tot4: '',
    tot5: '',
    tot6: '',
    tot7: '',
    tot8: '',
    tot9: '',
})

const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'quote_id': myidd
  }


  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  } 

  const total = Number(state.qty)*Number(state.price)
  const total1 = Number(state.qty1)*Number(state.price1)
  const total2 = Number(state.qty2)*Number(state.price2)
  const total3 = Number(state.qty3)*Number(state.price3)
  const total4 = Number(state.qty4)*Number(state.price4)
  const total5 = Number(state.qty5)*Number(state.price5)
  const total6 = Number(state.qty6)*Number(state.price6)
  const total7 = Number(state.qty7)*Number(state.price7)
  const total8 = Number(state.qty8)*Number(state.price8)
  const total9 = Number(state.qty9)*Number(state.price9)


const getquoteinfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setquote(0);
      }else{
        const sortedArray = response.data.data[0];
        // console.log(sortedArray)
        setquote(sortedArray);
        setquote(sortedArray);
      }
    });
  };

    useEffect(() => {
        getquoteinfo();
          setState((prevState) => ({
            ...prevState,
            projtitle: quote_info.projtitle,
            timeline: quote_info.timeline,
            country: quote_info.country,
            title: quote_info.title,
            title1: quote_info.title1,
            title2: quote_info.title2,
            title3: quote_info.title3,
            title4: quote_info.title4,
            title5: quote_info.title5,
            title6: quote_info.title6,
            title7: quote_info.title7,
            title8: quote_info.title8,
            title9: quote_info.title9,
            desc: quote_info.description,
            desc1: quote_info.description1,
            desc2: quote_info.description2,
            desc3: quote_info.description3,
            desc4: quote_info.description4,
            desc5: quote_info.description5,
            desc6: quote_info.description6,
            desc7: quote_info.description7,
            desc8: quote_info.description8,
            desc9: quote_info.description9,
            price: quote_info.price,
            price1: quote_info.price1,
            price2: quote_info.price2,
            price3: quote_info.price3,
            price4: quote_info.price4,
            price5: quote_info.price5,
            price6: quote_info.price6,
            price7: quote_info.price7,
            price8: quote_info.price8,
            price9: quote_info.price9,
            qty: quote_info.qty,
            qty1: quote_info.qty1,
            qty2: quote_info.qty2,
            qty3: quote_info.qty3,
            qty4: quote_info.qty4,
            qty5: quote_info.qty5,
            qty6: quote_info.qty6,
            qty7: quote_info.qty7,
            qty8: quote_info.qty8,
            qty9: quote_info.qty9,
            tot:  quote_info.tot,
            tot1: quote_info.tot1,
            tot2: quote_info.tot2,
            tot3: quote_info.tot3,
            tot4: quote_info.tot4,
            tot5: quote_info.tot5,
            tot6: quote_info.tot6,
            tot7: quote_info.tot7,
            tot8: quote_info.tot8,
            tot9: quote_info.tot9,
          }));
        // }, []);
      }, [quote_info.projtitle]);

    const handleChange2 = (fieldName) => (selectedOption) => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value
      }));
    };

    const token = localStorage.getItem('ladpsouthafricaaccesstoken');
      const headers2 = { 
        headers: {
          'AccessToken': token,
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': "*",
        }
      };

      const Updatequote = async (e) => {
        e.preventDefault();
        if (!state.projtitle || !state.country|| !state.timeline|| !state.title|| !state.desc|| !state.qty) {
          MySwal.fire({
            title: <p>Please enter all required fields</p>,
            confirmButtonColor: '#3085d6',
            didOpen: () => {
            },
          }).then(() => {
          });
        }else{
          MySwal.showLoading()
           const dashURL = BASE_URL+'/update_quote_requests'
           const postData = {
          'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
          'supplier_id': myidd,
          'supplier_mail': org_info.contact_mail,
          'supplier_name': org_info.suppliername,
           'projtitle': state.projtitle,
           'title': state.title,
           'country': state.country,
           'timeline': state.timeline,
           'title': state.title,
           'desc': state.desc,
           'qty': state.qty,
           'title1': state.title1,
           'title2': state.title2,
           'title3': state.title3,
           'title4': state.title4,
           'title5': state.title5,
           'title6': state.title6,
           'title7': state.title7,
           'title8': state.title8,
           'title9': state.title9,
           'desc1': state.desc1,
           'desc2': state.desc2,
           'desc3': state.desc3,
           'desc4': state.desc4,
           'desc5': state.desc5,
           'desc6': state.desc6,
           'desc7': state.desc7,
           'desc8': state.desc8,
           'desc9': state.desc9,
           'qty1': state.qty1,
           'qty2': state.qty2,
           'qty3': state.qty3,
           'qty4': state.qty4,
           'qty5': state.qty5,
           'qty6': state.qty6,
           'qty7': state.qty7,
           'qty8': state.qty8,
           'qty9': state.qty9,
           'tot': total,
           'tot1': total1,
           'tot2': total2,
           'tot3': total3,
           'tot4': total4,
           'tot5': total5,
           'tot6': total6,
           'tot7': total7,
           'tot8': total8,
           'tot9': total9 ,
          'price': state.price,
          'price1': state.price1,
          'price2': state.price2,
          'price3': state.price3,
          'price4': state.price4,
          'price5': state.price5,
          'price6': state.price6,
          'price7': state.price7,
          'price8': state.price8,
          'price9': state.price9,
          'id': myidd,
           'status': "Supplier has Updated",
           }
           console.log(postData);
           MySwal.fire({
               title: <p>please wait...</p>,
               didOpen: () => {
                 MySwal.showLoading()
                 axios.post(dashURL, postData, headers2)
               .then((response) => {
                 console.log("response received: ", response);
                 Swal.close()
                 Swal.fire({
                   title: "Successful",
                   text: "Quote Sent Successfully",
                   icon: "success",
                   showCancelButton: false,
                   confirmButtonText: "Okay",
                   confirmButtonColor: '#3085d6',
                   closeOnCancel: true,
                 }).then(result => {
               window.location.reload(false);
               window.close()
                 })
                 })
                 .catch((err) => {
                 Swal.close()
                   console.log("AXIOS ERROR: ", err);
                   if(!err.response){
                   return MySwal.fire(err.message)
                   }else{
                   return MySwal.fire(err.response.data.message)
                   }
                 })
               },
             }).then(() => {
             })
       }
      };


      return (
     <div>
     {/* <Header/> */}
     <br/>
     <br/>
     <br/>
     <br/>
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Request Quote from Supplier</h3>
          </div>
          {/* <div className="card-toolbar">
                 <button style={{float: 'right'}} onClick={allquotes} className="btn btn-info btn-active-light-info me-2">View all Quotes</button>
                 </div> */}
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Project Title</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" disabled  value={state.projtitle} id="projtitle" name="projtitle" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Estimated Delivery Timeline</label>
                  <input type="date" className="form-control  mb-3 mb-lg-0" disabled value={state.timeline} id="timeline" name="timeline" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Project Location</label>
                <input type="text" className="form-control  mb-3 mb-lg-0" disabled value={state.country} id="country" name="country" onChange={handleChange} />
                
               </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br/>

      <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <div className="card-title m-0 d-flex justify-content-between align-items-center">
          <h3 className="fw-bold m-0">Enter Line Items</h3>
         
        </div>
        </div>
         <p style={{marginLeft: '27px', color: 'red'}}>Update the unit price for each line item.</p>
       
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">Item Title</label>
                  <input type="text" disabled className="form-control disabled  mb-3 mb-lg-0"  value={state.title} id="title" name="title" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <label className="required fw-semibold fs-6 mb-2">Item Description</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc} id="desc" name="desc" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className="required fw-semibold fs-6 mb-2">Item Quantity</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty} id="qty" name="qty" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className="required fw-semibold fs-6 mb-2">Unit Price</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price} id="price" name="price" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className="required fw-semibold fs-6 mb-2">Total Amount</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" disabled value={total} id="tot" name="tot" onChange={handleChange} />
                </div>
              </div>
              {quote_info.title1? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title1} id="title1" name="title1" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc1} id="desc1" name="desc1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty1} id="qty1" name="qty1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price1} id="price1" name="price1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total1} id="tot1" name="tot1" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title2? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title2} id="title2" name="title2" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc2} id="desc2" name="desc2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty2} id="qty2" name="qty2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price2} id="price2" name="price2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total2} id="tot2" name="tot2" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title3? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title3} id="title3" name="title3" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc3} id="desc3" name="desc3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty3} id="qty3" name="qty3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price3} id="price3" name="price3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total3} id="tot3" name="tot3" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title4? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title4} id="title4" name="title4" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc4} id="desc4" name="desc4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty4} id="qty4" name="qty4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price4} id="price4" name="price4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total4} id="tot4" name="tot4" onChange={handleChange} />
                </div>
              </div>
              : ''}
             
             {quote_info.title5? 
            //   <div id='morefields'>
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title5} id="title5" name="title5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc5} id="desc5" name="desc5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty5} id="qty5" name="qty5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price5} id="price5" name="price5" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total5} id="tot5" name="tot5" onChange={handleChange} />
                </div>
                </div>
              : ''}
             {quote_info.title6? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title6} id="title" name="title" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc6} id="desc6" name="desc6" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty6} id="qty6" name="qty6" onChange={handleChange} />
                  </div>
                 <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price6} id="price6" name="price6" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total6} id="tot6" name="tot6" onChange={handleChange} />
                </div>
                </div>
              : ''}

             {quote_info.title7? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title7} id="title7" name="title7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc7} id="desc7" name="desc7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty7} id="qty7" name="qty7" onChange={handleChange} />
                  </div> 
                  <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price7} id="price7" name="price7" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total7} id="tot7" name="tot7" onChange={handleChange} />
                </div>
                </div>
              : ''}

             {quote_info.title8? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title8} id="title8" name="title8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc8} id="desc8" name="desc8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty8} id="qty8" name="qty8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price8} id="price8" name="price8" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total8} id="tot8" name="tot8" onChange={handleChange} />
                </div>
                </div>
              : ''}

            {quote_info.title9? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.title9} id="title9" name="title9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.desc9} id="desc9" name="desc9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" className="form-control  mb-3 mb-lg-0" value={state.qty9} id="qty9" name="qty9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.price9} id="price9" name="price9" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={total9} id="tot9" name="tot9" onChange={handleChange} />
                </div>
                </div>
              : ''}
         

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              {/* <a href='viewsupplier'  className="btn btn-light btn-active-light-primary me-2">Discard</a> */}
              <button onClick={Updatequote} className="btn btn-info" id="kt_account_profile_details_submit">Submit Quote</button>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
 </div>
</div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Editquote;
