import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import CitiesAndProvincesSouthAfrica from './cities';
import withReactContent from 'sweetalert2-react-content'
import BusinessCategories from './BusinessCategories';
import BBEEE from './bbeee';
import Select from 'react-select';
import BASE_URL from './config';
import axios from "axios";
import storage from "./firebaseConfig.js"
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";


function Editsupplier(){
  const MySwal = withReactContent(Swal)
  const [supp_info, setsupp] = useState([]);
  const suppURL = BASE_URL+'/fetchSupplierbyID'
  const orgupdateURL = BASE_URL+'/update_supplier_link'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const [state , setState] = useState({
    name: '',
    country: '',
    regno: '',
    vat: '',
    bbbee: '',
    esg: '',
    emp_no: '',
    businesscategory: '',
    contactperson: '',
    revenue: '',
    contactphone2: '',
    contactphone: '',
    contactaddress: '',
    contactcity: '',
    contactemail: '',
    contactemail2: '',
    contactpaddress: '',
    contactsuburb: '',
    contactcity: '',
    supplierscol: '',
    bbee_url: '',
    esg_url: '',
    client_id: '',
    client_id2: '',
    client_id3: '',
    client_id4: '',
    client_id5: '',
    blackowned : '',
    blackwomanowned : '',
    designatedgroup : '',
    companytype : ''
})

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'supplier_id': myidd
  }

  const getorginfo = async (e) => {
    axios.post(suppURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setsupp(0);
      }else{
        const sortedArray = response.data.data[0];
        console.log(sortedArray)
        setsupp(sortedArray);
        setsupp(sortedArray);
      }
    });
  };

  

  useEffect(() => {
    getorginfo();
      // When org_info.firstname changes, update state.patientName
      setState((prevState) => ({
        ...prevState,
        name: supp_info.suppliername,
        country: supp_info.supplierlocation,
        supplierregno: supp_info.clientregno,
        regno: supp_info.supplierregno,
        vat: supp_info.suppliervat,
        bbbee: supp_info.supplierbbbee,
        email: supp_info.clientemail,
        phone: supp_info.client_phone,
        esg: supp_info.supplieresg,
        emp_no: supp_info.no_of_employees,
        revenue: supp_info.revenue,
        businesscategory: supp_info.business_cat,
        contactperson: supp_info.contact_name,
        contactphone: supp_info.contact_phone,
        contact_phone_2: supp_info.contactphone2,
        contactemail: supp_info.contact_mail,
        contactemail2: supp_info.contact_mail_2,
        contactaddress: supp_info.contact_address,
        contactcity: supp_info.contact_city,
        email: supp_info.org_mail,
        contactsuburb: supp_info.contact_suburb,
        contactpaddress: supp_info.contact_postal,
        contactemail: supp_info.contact_mail,
        client_id: state.client_id,
        blackowned : supp_info.blackowned,
        blackwomanowned : supp_info.blackwomanowned,
        designatedgroup : supp_info.designatedgroup,
        companytype : supp_info.companytype
        // bbee_url: org_info.bbbeeurl,
        // esg_url: org_info.esgurl,
        
      }));
    }, [supp_info.suppliername]);

const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};

const handleFileChange = (event, inputName) => {
  const file = event.target.files[0];
  if (file) {
    setState({
      ...state,
      [inputName]: file, // Store the file object in state
    });
  }
};

const handleImageUpload = async (file) => {
  const storageRef = ref(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        // Get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    );
  });
};



const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 


  const discard = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will lose unsaved changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, discard!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Close the page or perform any other action here
        window.close(); // Close the current tab/window
      }
    });
  };

const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const createOrg = async (e) => {
  e.preventDefault();
  if (!state.name || !state.country|| !state.regno|| !state.vat|| !state.bbbee|| !state.esg|| !state.emp_no|| !state.businesscategory|| !state.contactperson|| !state.revenue|| !state.contactphone|| !state.contactaddress|| !state.contactcity|| !state.contactemail|| !state.contactcity) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
     MySwal.showLoading()
     const esgreport = state.esg_url? await handleImageUpload(state.esg_url): '';
     const bbbeereport = state.bbee_url? await handleImageUpload(state.bbee_url): '';
     console.log(state.bbee_url)
     console.log(esgreport)
     const postData = {
        'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
        'suppliername': state.name,
        'supplierlocation': state.country,
        'supplierregno': state.regno,
        'suppliervat': state.vat,
        'supplierbbbee': state.bbbee,
        'supplieresg': state.esg,
        'no_of_employees': state.emp_no,
        'business_cat': state.businesscategory,
        'revenue_range': state.revenue,
        'contact_name': state.contactperson,
        'contact_phone': state.contactphone,
        'contact_phone_2': state.contactphone2,
        'contact_mail': state.contactemail,
        'contact_mail_2': state.contactemail2,
        'contact_suburb': state.contactsuburb,
        'contact_city': state.contactcity,
        'contact_address': state.contactaddress,
        'contact_postal': state.contactpaddress,
        'supplierscol': '2',
        'bbee_url': bbbeereport,
        'esg_url': esgreport,
        'client_id': state.client_id,
        'client_id2': state.client_id2,
        'client_id3': state.client_id3,
        'client_id4': state.client_id4,
        'client_id5': state.client_id5,
        'blackowned': state.blackowned,
        'blackwomanowned': state.blackwomanowned,
        'designatedgroup': state.designatedgroup,
        'companytype': state.companytype,
        'supplier_id': supp_info.id,
        'supplierId': supp_info.id,
     }
     console.log(postData);
      Swal.close()
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(orgupdateURL, postData, headers2)
           .then(() => { // Rename response to avoid confusion
            console.log("response received");
            Swal.close();
            Swal.fire({
              title: "Thank you",
              text: "Supplier Updated successfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: "Okay",
              closeOnCancel: true,
            }).then(() => { // Use an empty arrow function here
              window.close(); // Close the window
            });
          })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
        window.close(); // Close the window
       })
 }
};




      return (
     <div>
     {/* <Header/> */}
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
            <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Edit Supplier Information</h3>
          </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
            <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Name</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.name} id="name" name="name" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Supplier Location</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={Countries}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Registration Number</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0" value={state.regno} id="regno" name="regno" onChange={handleChange} />
                </div>
               </div>
              
               <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier VAT Number</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.vat} id="vat" name="vat" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier B-BBEE Level</label>
                  <select  aria-label="Select"  value={state.bbbee} id="bbbee" name="bbbee" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="LEVEL 1">LEVEL 1</option>
                    <option  value="LEVEL 2">LEVEL 2</option>
                    <option value="LEVEL 3">LEVEL 3</option>
                    <option  value="LEVEL 4">LEVEL 4</option>
                    <option value="LEVEL 5">LEVEL 5</option>
                    <option  value="LEVEL 6">LEVEL 6</option>
                    <option  value="LEVEL 7">LEVEL 7</option>
                    <option  value="LEVEL 8">LEVEL 8</option>
                    <option  value="NON COMPLIANCE">NON COMPLIANT </option>
                    <option  value="NON COMPLIANCE">NONE </option>
                  </select>
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier ESG Report</label>
                  <select  aria-label="Select"  value={state.esg} id="esg" name="esg" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
              </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Is &gt; 51% Black Owned</label>
                  <select  aria-label="Select"  value={state.blackowned} id="blackowned" name="blackowned" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Is &gt; 30% Black Woman Owned</label>
                  <select  aria-label="Select"  value={state.blackwomanowned} id="blackwomanowned" name="blackwomanowned" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Is &gt; 51% Designated Group Company </label>
                  <select  aria-label="Select"  value={state.designatedgroup} id="designatedgroup" name="designatedgroup" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
              </div>


              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">No of Employees</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.emp_no} id="emp_no" name="emp_no" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Service Category</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.businesscategory, label: state.businesscategory }}  onChange={handleChange2('businesscategory')}  
                           options={BusinessCategories}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Annual Revenue Range</label>
                  <select  aria-label="Select"  value={state.revenue} id="revenue" name="revenue" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="R10, 000-R50,000">R10,000 - R50,000</option>
                    <option  value="R50,000 - R100,000">R50,000 - R100,000</option>
                    <option value="R100,000 - R500,000">R100,000 - R500,000</option>
                    <option  value="R500,000 - R1,000,000">R500,000 - R1,000,000</option>
                    <option value="R1,000,000 - R5,000,000">R1,000,000 - R5,000,000</option>
                    <option  value="R5,000,000 - R10,000,000">R5,000,000 - R10,000,000</option>
                    <option  value="R10,000,000 - R50,000,000">R10,000,000 - R50,000,000</option>
                    <option  value="R50,000,000 -">R50,000,000 - </option>
                  </select>
                </div>
               </div>


              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Person</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactperson} id="contactperson" name="contactperson" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Person Phone</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactphone} id="contactphone" name="contactphone" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Person Phone 2</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactphone2} id="contactphone2" name="contactphone2" onChange={handleChange} />
                </div>
              </div>
             
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Email</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactemail} id="contactemail" name="contactemail" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Email 2</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactemail2} id="contactemail2" name="contactemail2" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Suburb</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactsuburb} id="contactsuburb" name="contactsuburb" onChange={handleChange} />
                </div>
              </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact City </label>
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.contactcity, label: state.contactcity }}  onChange={handleChange2('contactcity')}  
                           options={CitiesAndProvincesSouthAfrica}
                        />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Contact Address</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactaddress} id="contactaddress" name="contactaddress" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Supplier Contact Postal Address </label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.contactpaddress} id="contactpaddress" name="contactpaddress" onChange={handleChange} />
                </div>
              </div>
             
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Business Type</label>
                  <select  aria-label="Select"  value={state.companytype} id="companytype" name="companytype" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="Exempted Micro Enterprise">Exempted Micro Enterprise (EME)</option>
                    <option  value="Qualifying small Enterprise">Qualifying small Enterprise (QSE)</option>
                    <option  value="Generic Company">Generic Company (GNC)</option>
                  </select>
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload ESG Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="esg_url" name="esg_url" onChange={(event) => handleFileChange(event, 'esg_url')}/>
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload BBBEE Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="bbee_url" name="bbee_url" onChange={(event) => handleFileChange(event, 'bbee_url')}  />
                </div>
                </div>
                
            

            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a onClick={discard}  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button onClick={createOrg} className="btn btn-info" id="kt_account_profile_details_submit">Submit Changes</button>
            </div>
          </form>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
        );
 
  };

export default Editsupplier;
