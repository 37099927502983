import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import Select from 'react-select';
import dayjs from "dayjs";
import BASE_URL from './config';
import html2pdf from 'html2pdf.js';
import { jsPDF } from "jspdf";


function Printquote(){
  const MySwal = withReactContent(Swal);
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [supp_info, setsupp] = useState(['']);
  const history = useHistory();
  const [quote_info, setquote] = useState(['']);
  const [client_info, setclients] = useState(['']);
  const orgURL = BASE_URL+'/fetchquotesbyID'
  const suppURL = BASE_URL+'/fetchSupplierbyID'
  const clientidURL = BASE_URL+'/fetchclientsbyID'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const [state , setState] = useState({
    projtitle: '',
    country: '',
    timeline: '',
    title: '',
    title1: '',
    title2: '',
    title3: '',
    title4: '',
    title5: '',
    title6: '',
    title7: '',
    title8: '',
    title9: '',
    desc: '',
    desc1: '',
    desc2: '',
    desc3: '',
    desc4: '',
    desc5: '',
    desc6: '',
    desc7: '',
    desc8: '',
    desc9: '',
    qty: '',
    qty1: '',
    qty2: '',
    qty3: '',
    qty4: '',
    qty5: '',
    qty6: '',
    qty7: '',
    qty8: '',
    qty9: '',
    price: '',
    price1: '',
    price2: '',
    price3: '',
    price4: '',
    price5: '',
    price6: '',
    price7: '',
    price8: '',
    price9: '',
    tot: '',
    tot1: '',
    tot2: '',
    tot3: '',
    tot4: '',
    tot5: '',
    tot6: '',
    tot7: '',
    tot8: '',
    tot9: '',
})

const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'quote_id': myidd,
    'client_id': quote_info.client_id,
    'supplier_id': quote_info.supplier_id,
  }


  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  } 


const getquoteinfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setquote(0);
      }else{
        const sortedArray = response.data.data[0];
        console.log(sortedArray)
        setquote(sortedArray);
        setquote(sortedArray);
      }
    });
  };

  const getclientinfo = async (e) => {
    await getquoteinfo(); // Assuming this is another async function you want to call before making the axios request
    try {
      const response = await axios.post(clientidURL, body, headers2);
      console.log("response received: ", response);
  
      if (!response || !response.data || response.data.data.length === 0) {
        setclients(0);
      } else {
        const sortedArray = response.data.data[0];
        console.log(sortedArray);
        setclients(sortedArray);
      }
    } catch (error) {
      console.error('Error fetching client info:', error);
      setclients(0); // Set to 0 in case of an error
    }
  };

  const getsupplierinfo = async (e) => {
    await getquoteinfo(); // Assuming this is another async function you want to call before making the axios request
    try {
      const response = await axios.post(suppURL, body, headers2);
      console.log("response received: ", response);
  
      if (!response || !response.data || response.data.data.length === 0) {
        setsupp(0);
      } else {
        const sortedArray = response.data.data[0];
        console.log(sortedArray);
        setsupp(sortedArray);
      }
    } catch (error) {
      console.error('Error fetching client info:', error);
      setsupp(0); // Set to 0 in case of an error
    }
  };
  

    const allquotes = (e) => {
      window.location.href='/allquotes'
    }

    useEffect(() => {
        getquoteinfo();
        getclientinfo();
        getsupplierinfo();
          setState((prevState) => ({
            ...prevState,
            projtitle: quote_info.projtitle,
            timeline: quote_info.timeline,
            country: quote_info.country,
            title: quote_info.title,
            title1: quote_info.title1,
            title2: quote_info.title2,
            title3: quote_info.title3,
            title4: quote_info.title4,
            title5: quote_info.title5,
            title6: quote_info.title6,
            title7: quote_info.title7,
            title8: quote_info.title8,
            title9: quote_info.title9,
            desc: quote_info.description,
            desc1: quote_info.description1,
            desc2: quote_info.description2,
            desc3: quote_info.description3,
            desc4: quote_info.description4,
            desc5: quote_info.description5,
            desc6: quote_info.description6,
            desc7: quote_info.description7,
            desc8: quote_info.description8,
            desc9: quote_info.description9,
            price: quote_info.price,
            price1: quote_info.price1,
            price2: quote_info.price2,
            price3: quote_info.price3,
            price4: quote_info.price4,
            price5: quote_info.price5,
            price6: quote_info.price6,
            price7: quote_info.price7,
            price8: quote_info.price8,
            price9: quote_info.price9,
            qty: quote_info.qty,
            qty1: quote_info.qty1,
            qty2: quote_info.qty2,
            qty3: quote_info.qty3,
            qty4: quote_info.qty4,
            qty5: quote_info.qty5,
            qty6: quote_info.qty6,
            qty7: quote_info.qty7,
            qty8: quote_info.qty8,
            qty9: quote_info.qty9,
            tot:  quote_info.tot,
            tot1: quote_info.tot1,
            tot2: quote_info.tot2,
            tot3: quote_info.tot3,
            tot4: quote_info.tot4,
            tot5: quote_info.tot5,
            tot6: quote_info.tot6,
            tot7: quote_info.tot7,
            tot8: quote_info.tot8,
            tot9: quote_info.tot9,
          }));
        }, [quote_info.projtitle]);

    const handleChange2 = (fieldName) => (selectedOption) => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value
      }));
    };

    const total_value = Number(quote_info.tot)+Number(quote_info.tot1)+Number(quote_info.tot2)+Number(quote_info.tot3)+Number(quote_info.tot4)+Number(quote_info.tot5)+Number(quote_info.tot6)+Number(quote_info.tot7)+Number(quote_info.tot8)+Number(quote_info.tot9);

    const token = localStorage.getItem('ladpsouthafricaaccesstoken');
      const headers2 = { 
        headers: {
          'AccessToken': token,
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': "*",
        }
      };

      function print() {
        const element = document.getElementById('divToPrint');
        if (element) {
            import('html2pdf.js').then(module => {
                const html2pdf = module.default; // Access the default export
                const opt = {
                    margin: 0.5,
                    filename: 'Purchase_Order.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                };
                html2pdf().from(element).set(opt).save();
            }).catch(error => {
                console.error('Error loading html2pdf.js:', error);
            });
        }
    }
    
    

  
      return (
     <div>
     {/* <Header/> */}
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
          <div id="kt_toolbar_container" className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
             
            </div>
            <a onClick={print} className="btn btn-info">Download PDF</a> 
          </div>
        </div>
        <div id="" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="divToPrint" >
            <div className="card mb-5 mb-xl-12">
                <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                <div className="card-title m-0">
                    <h2 className="fw-bold m-0">PURCHASE ORDER</h2> 
                   
                </div>
                <div className="card-toolbar">
               
                </div>
                </div>
                <div id="kt_account_settings_profile_details" className="collapse show">
                <form id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                  <div className='fv-row mb-12 col-12 row'>
                  <div className='col-lg-6'>
                   <h3>{client_info.clientname}</h3><br/>
                    {client_info.org_address}<br/>
                    {client_info.org_city}<br/>
                    {client_info.contact_phone}<br/>
                    {client_info.org_mail}<br/>
                 </div>
                    <div className='col-lg-6' style={{textAlign: 'right'}}>
                    <b>Date</b>: {dayjs(Date()).format("DD MMM YY")}
                    <p>
                    <b>Delivery Date</b>: {dayjs(quote_info.created_at).format("DD MMM YY")}
                    </p>
                    </div>
                  </div>
                    <h3>VENDOR</h3><br/>
                    <h6>{supp_info.suppliername}</h6>
                    {supp_info.contact_address}<br/>
                    {supp_info.contact_city}<br/>
                    {supp_info.contact_mail}<br/>
                    {supp_info.contact_phone}<br/>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className="fv-row mb-12 col-12 row">
                    
                    <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2">Project Title</label>
                        <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.projtitle} id="projtitle" name="projtitle" onChange={handleChange} />
                        </div>
                        <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2"> Timeline</label>
                        <input type="date" disabled className="form-control  mb-3 mb-lg-0" value={state.timeline} id="timeline" name="timeline" onChange={handleChange} />
                        </div>
                        <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2">Project Location</label>
                        <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.country} id="country" name="country" onChange={handleChange} />
                    </div>
                    </div>
                    </div>
                </form>
                </div>
            </div>

            <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <div className="card-title m-0 d-flex justify-content-between align-items-center">
          <h3 className="fw-bold m-0">Line Items</h3>
        </div>
        
        </div>
       
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <label className=" fw-semibold fs-6 mb-2">Item Title</label>
                  <input type="text" disabled className="form-control disabled  mb-3 mb-lg-0"  value={state.title} id="title" name="title" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <label className=" fw-semibold fs-6 mb-2">Item Description</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc} id="desc" name="desc" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className=" fw-semibold fs-6 mb-2"> Quantity</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty} id="qty" name="qty" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className=" fw-semibold fs-6 mb-2">Unit Price</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price} id="price" name="price" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <label className="required fw-semibold fs-6 mb-2">Total</label>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot} id="tot" name="tot" onChange={handleChange} />
                </div>
              </div>
              {quote_info.title1? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title1} id="title1" name="title1" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc1} id="desc1" name="desc1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty1} id="qty1" name="qty1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price1} id="price1" name="price1" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot1} id="tot1" name="tot1" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title2? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title2} id="title2" name="title2" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc2} id="desc2" name="desc2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty2} id="qty2" name="qty2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price2} id="price2" name="price2" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot2} id="tot2" name="tot2" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title3? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title3} id="title3" name="title3" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc3} id="desc3" name="desc3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty3} id="qty3" name="qty3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price3} id="price3" name="price3" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot3} id="tot3" name="tot3" onChange={handleChange} />
                </div>
              </div>
              : ''}
              {quote_info.title4? 
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title4} id="title4" name="title4" onChange={handleChange} />
                </div>
                <div className='col-lg-3'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc4} id="desc4" name="desc4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty4} id="qty4" name="qty4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price4} id="price4" name="price4" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot4} id="tot4" name="tot4" onChange={handleChange} />
                </div>
              </div>
              : ''}
             
             {quote_info.title5? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title5} id="title5" name="title5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc5} id="desc5" name="desc5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty5} id="qty5" name="qty5" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price5} id="price5" name="price5" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot5} id="tot5" name="tot5" onChange={handleChange} />
                </div>
                </div>
              : ''}
             {quote_info.title6? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title6} id="title6" name="title6" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc6} id="desc6" name="desc6" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty6} id="qty6" name="qty6" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price6} id="price6" name="price6" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot6} id="tot6" name="tot6" onChange={handleChange} />
                </div>
                </div>
              : ''}

             {quote_info.title7? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title7} id="title7" name="title7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc7} id="desc7" name="desc7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty7} id="qty7" name="qty7" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price7} id="price7" name="price7" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot7} id="tot7" name="tot7" onChange={handleChange} />
                </div>
                </div>
              : ''}

             {quote_info.title8? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title8} id="title8" name="title8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc8} id="desc8" name="desc8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty8} id="qty8" name="qty8" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price8} id="price8" name="price8" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot8} id="tot8" name="tot8" onChange={handleChange} />
                </div>
                </div>
              : ''}

            {quote_info.title9? 
                <div className="fv-row mb-12 col-12 row">
                <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0"  value={state.title9} id="title9" name="title9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-3'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.desc9} id="desc9" name="desc9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                    <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.qty9} id="qty9" name="qty9" onChange={handleChange} />
                  </div>
                  <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.price9} id="price9" name="price9" onChange={handleChange} />
                </div>
                <div className='col-lg-2'>
                  <input type="text" disabled className="form-control  mb-3 mb-lg-0" value={state.tot9} id="tot9" name="tot9" onChange={handleChange} />
                </div>
                </div>
              : ''}
            </div>
            <div style={{textAlign: 'right'}}>
          <h4 style={{padding: '50px'}}>Total Amount: {total_value.toLocaleString()}</h4>
          </div>
         <center> <p><small>This purchase order is an approval to proceed with the supply/service stipulated above as per provided quotation.</small></p></center>
          </form>
          
        </div>
      </div>
          </div>
          </div>
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Printquote;
