import React, {useState,useEffect} from 'react';
import Header2 from './header2'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';



function Orgquotes(){
  const [org_info, setorg] = useState([]);
  const orgURL = BASE_URL+'/fetchorgquotes'
  const token = localStorage.getItem("ladpsouthafricaaccessorgtoken")
 

  const headers2 = { 
    headers: {
      'AccessToken': token,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccessorgtoken"),
    'client_id': localStorage.getItem("ladpsouthafricauerorgid"),

  }

  const getorginfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(0);
      }else{
        const sortedArray = response.data.data;
        const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
        setorg(numDescending);
        setorg(numDescending);
      }
    });
  };

  useEffect(() => {
    getorginfo();
  }, []);

  const columns = [
    {
      name: 'DATE REGISTERED',
      selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
      sortable: true,
      width: "12rem" ,
        cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
  },
  {
    name: 'QUOTE TITLE',
    selector: (row) => row.projtitle,
    sortable: true,
    width: '15rem',
    cellExport: row => row.projtitle 

  },
  {
    name: 'EXPECTED DELIVERY',
    selector: (row) => row.timeline,
    sortable: true,
    width: '12rem',
    cellExport: row => row.timeline 

  },
  {
    name: 'LOCATION',
    selector: (row) => row.country,
    sortable: true,
    width: '15rem',
    cellExport: row => row.country 

  },
  {
    name: 'SUPPLIER',
    selector: (row) => row.supplier_name,
    sortable: true,
    width: '15rem',
    cellExport: row => row.supplier_name 

  },
  {
    name: 'STATUS',
    selector: (row) => row.status,
    sortable: true,
    width: '15rem',
    cellExport: row => row.status

  },
  {
    name: 'ACTIONS',
    button: true,
    width: '10rem',
    cell: (row) => <button onClick={() => vieworganization(row.id)} className="btn btn-sm btn-info btn-active-light-primary">View</button>
  },
 
 
];
  
const data=org_info;

const tableData = {
  columns,
  data
};

const vieworganization = (selectedId) => {
  const selectedID = selectedId;
  if (selectedID) {
    const nextPageUrl = '/orgviewquote'; 
    window.location.href = nextPageUrl;
    window.location.href = "orgviewquote?myid=" + selectedID;
  } else {
    console.error(`Data for ID ${selectedId} not found`);
  }
}

      return (
     <div>
     <Header2/>
     <div>
        <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
          <div id="kt_toolbar_container" className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                All Quotes
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
            
          </div>
        </div>
        
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">View all</span>
                </h3>
                
              </div>
              <div className="card-body py-3">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {/*begin::Table*/}
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  {org_info == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                  {/*end::Table*/}
                </div>
                {/*end::Table container*/}
              </div>
              {/*begin::Body*/}
            </div>
          </div>
          </div>
          </div>
     <Footer/>
              </div>
               
        );
 
  };

export default Orgquotes;
