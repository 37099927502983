import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header3 from './header3'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import BusinessCategories from './BusinessCategories';
import Select from 'react-select';


function Newstudent(){

  const [state , setState] = useState({
    name: '',
    lname: '',
    country: '',
    regno: '',
    vat: '',
    bbbee: '',
    esg: '',
    emp_no: '',
    businesscategory: '',
    contactperson: '',
    revenue: '',
    contactphone2: '',
    contactphone: '',
    contactaddress: '',
    contactcity: '',
    contactemail: '',
    contactemail2: '',
    contactpaddress: '',
    contactsuburb: '',
    contactcity: '',
   
})

localStorage.setItem('slmed_quote_provider', state.provider);

const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};



  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }
      return (
     <div>
     <Header3/>
     <br/>
     <br/>
     <br/>
     <br/>
    
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
            <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Add New Student</h3>
          </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student Firstname</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.name} id="name" name="name" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Student Lastname</label>
                <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.lname} id="lname" name="lname" onChange={handleChange} />

               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student EE Number</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0" value={state.regno} id="regno" name="regno" onChange={handleChange} />
                </div>
               </div>
              
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student ID Number</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.vat} id="vat" name="vat" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student Race</label>
                  <select  aria-label="Select" data-control="select2" value={state.bbbee} id="bbbee" name="bbbee" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="African">African</option>
                    <option  value="White">White </option>
                    <option value="Indian">Indian </option>
                    <option  value="Asian">Asian </option>
                    <option  value="LEVEL">Coloured </option>
                    <option  value="NON COMPLIANCE">Other</option>
                  </select>
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Supplier Gender</label>
                  <select  aria-label="Select" data-control="select2" value={state.esg} id="esg" name="esg" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">Male</option>
                    <option  value="NO">Female</option>
                  </select>
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Student Email</label>
                  <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.emp_no} id="emp_no" name="emp_no" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Student Phone</label>
                <input type="text" className="form-control form-control-solid mb-3 mb-lg-0"  value={state.phone} id="phone" name="phone" onChange={handleChange} />
               </div>
               </div>
              
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='studentlist'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button type="submit" className="btn btn-info" id="kt_account_profile_details_submit">Create Student</button>
            </div>
          </form>
        </div>
      </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Newstudent;
