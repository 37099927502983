import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import withReactContent from 'sweetalert2-react-content'
import BusinessCategories from './BusinessCategories';
import CitiesAndProvincesSouthAfrica from './cities';
import BBEEE from './bbeee';
import Select from 'react-select';
import BASE_URL from './config';
import axios from "axios";
import storage from "./firebaseConfig.js"
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";


function Neworganization(){
  const MySwal = withReactContent(Swal)
  const [state , setState] = useState({
    clientname: '',
    country: '',
    regno: '',
    vat: '',
    bbbee: '',
    esg: '',
    emp_no: '',
    businesscategory: '',
    contactperson: '',
    revenue: '',
    contactphone2: '',
    contactphone: '',
    contactaddress: '',
    contactcity: '',
    contactpaddress: '',
    contactsuburb: '',
    contactcity: '',
})


const handleChange2 = (fieldName) => (selectedOption) => {
  setState((prevState) => ({
    ...prevState,
    [fieldName]: selectedOption.value
  }));
};

const handleFileChange = (event, inputName) => {
  const file = event.target.files[0];
  if (file) {
    setState({
      ...state,
      [inputName]: file, // Store the file object in state
    });
  }
};

const handleImageUpload = async (file) => {
  // Check if file exists
  if (!file) {
    return Promise.resolve(null); // Resolve with null if file is empty
  }

  const storageRef = ref(storage, `/files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        // Get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            resolve(url);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }
    );
  });
};



const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 

const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const createOrg = async (e) => {
  e.preventDefault();
  if (!state.clientname || !state.country|| !state.regno|| !state.vat|| !state.bbbee|| !state.esg|| 
    !state.businesscategory|| !state.contactphone|| !state.contactperson|| !state.phone||  !state.email|| 
    !state.contactsuburb|| !state.contactcity|| !state.contactaddress) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      confirmButtonColor: '#3085d6',
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
     MySwal.showLoading()
     const dashURL = BASE_URL+'/createclient'
     const esgreport = await handleImageUpload(state.esg_url);
     const bbbeereport = await handleImageUpload(state.bbee_url);
     console.log(bbbeereport)
     console.log(esgreport)
     const postData = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
     'clientname': state.clientname,
     'country': state.country,
     'regno': state.regno,
     'vat': state.vat,
     'bbbee': state.bbbee,
     'email': state.email,
     'phone': state.phone,
     'esg': state.esg,
     'emp_no': '20',
     'businesscategory': state.businesscategory,
     'contactperson': state.contactperson,
     'revenue': state.revenue,
     'contact_phone': state.contactphone,
     'contact_phone_2': state.contactphone2,
     'contactaddress': state.contactaddress,
     'contactcity': state.contactcity,
     'email': state.email,
     'contactsuburb': state.contactsuburb,
     'contactpaddress': state.contactpaddress,
     'bbbeeurl': bbbeereport,
     'esgurl': esgreport
     }
     console.log(postData);
      Swal.close()
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "Client Created successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonColor: '#3085d6',
             confirmButtonText: "Okay",
             closeOnCancel: true,
           }).then(result => {
         window.location.href = 'organizations';
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};


      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <br/>
     <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Add New Organization</span>
                </h3>
              </div>
              <div className="card-body py-3">
              <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Name</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.clientname} id="clientname" name="clientname" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Organization Location</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={Countries}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Registration Number</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0" value={state.regno} id="regno" name="regno" onChange={handleChange} />
                </div>
               </div>
              
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization VAT Number</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.vat} id="vat" name="vat" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization B-BBEE Level</label>
                  <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.bbbee, label: state.bbbee }}  onChange={handleChange2('bbbee')}  
                           options={BBEEE}
                        />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Esg</label>
                  <select  aria-label="Select" value={state.esg} id="esg" name="esg" onChange={handleChange}  className="form-select form-select-solid form-select-lg">
                    <option value>Select...</option>
                    <option  value="YES">YES</option>
                    <option  value="NO">NO</option>
                  </select>
                </div>
              </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Phone</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.phone} id="phone" name="phone" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                <label className="required fw-semibold fs-6 mb-2">Business Category</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.businesscategory, label: state.businesscategory }}  onChange={handleChange2('businesscategory')}  
                           options={BusinessCategories}
                        />
               </div>
               <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Contact Person</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactperson} id="contactperson" name="contactperson" onChange={handleChange} />
                </div>
               </div>
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Contact Person Phone</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactphone} id="contactphone" name="contactphone" onChange={handleChange} />
                </div>
            
                <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Organization Contact Person Phone 2</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactphone2} id="contactphone2" name="contactphone2" onChange={handleChange} />
                </div>
                <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Email</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.email} id="email" name="email" onChange={handleChange} />
                </div>
              </div>
             
              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Suburb</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactsuburb} id="contactsuburb" name="contactsuburb" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
              <label className="required fw-semibold fs-6 mb-2">Organization City</label>
              <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.contactcity, label: state.contactcity }}  onChange={handleChange2('contactcity')}  
                           options={CitiesAndProvincesSouthAfrica}
                        />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Organization Address</label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactaddress} id="contactaddress" name="contactaddress" onChange={handleChange} />
                </div>
              </div>

              <div className="fv-row mb-12 col-12 row">
              <div className='col-lg-4'>
                  <label className=" fw-semibold fs-6 mb-2">Organization Postal Address </label>
                  <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactpaddress} id="contactpaddress" name="contactpaddress" onChange={handleChange} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload ESG Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"  id="esg_url" name="esg_url" onChange={(event) => handleFileChange(event, 'esg_url')} />
                </div>
              <div className='col-lg-4'>
                  <label className="required fw-semibold fs-6 mb-2">Upload BBBEE Report</label>
                  <input type="file" className="form-control form-control-solid mb-3 mb-lg-0"   id="bbee_url" name="bbee_url" onChange={(event) => handleFileChange(event, 'bbee_url')} />
                </div>
                
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <a href='organizations'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
              <button onClick={createOrg} className="btn btn-info" id="kt_account_profile_details_submit">Create Organization</button>
            </div>
          </form>
              </div>
              {/*begin::Body*/}
            </div>
          </div>
          </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Neworganization;
