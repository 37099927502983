import React, {useState,useEffect} from 'react';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2';



function Signin(){

    const MySwal = withReactContent(Swal)
    const [state , setState] = useState({
      loginemail: ''
    })
    
    const loginUrl = "https://radiitech.tech/api/forgot";
    
    const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
    }
    var postloginData = {
      "email": state.email,
    //   "password": state.loginpassword,
    };
    
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
    
      const handleLoginSubmit = (e) => {
        e.preventDefault()
        if (!state.email){
          MySwal.fire({
            title: <p>Please fill all fields</p>,
            didOpen: () => {
            },
          }).then(() => {
          })
        }else{
          MySwal.fire({
            title: <p>please wait...</p>,
            didOpen: () => {
              MySwal.showLoading()
              axios.post(loginUrl, postloginData, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
              Swal.close()
                window.location.href="verifyauth";
              })
              .catch((err) => {
                Swal.close()
                console.log("AXIOS ERROR: ", err);
                if (err.response.data.message){
                  return MySwal.fire(err.response.data.message)
                } else {
                  return MySwal.fire(err.response.message)
                }
              })
            },
          }).then(() => {
          })
        }
      }
    

      return (
     <div>
      <div className="d-flex flex-column flex-root" style={{backgroundImage: 'url(assets/media/bglogin.jpg)', backgroundSize: 'contain'}}>
        {/* <style dangerouslySetInnerHTML={{__html: "\nbody {\nbackground-image: url('assets/media/bglogin.jpg');\n}\n\n[data-bs-theme=\"dark\"] body {\nbackground-image: url('assets/media/auth/bg4-dark.jpg');\n}\n"}} /> */}
          <div className="d-flex flex-column flex-column-fluid flex-lg-row">
            <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">              
              <div className="d-flex flex-center flex-lg-start flex-column">              
                <a href="index.html" className="mb-7">
                  {/* <img alt="Logo" src="assets/media/logos/custom-3.svg" /> */}
                  <h1 style={{color: 'white'}}>LADP</h1>
                </a>    
                <h2 className="text-white fw-normal m-0"> 
                Empowering Suppliers, Enhancing Efficiency!
                </h2>  
              </div>
            </div>
          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
              <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="/metronic8/demo16/../demo16/index.html" action="#">
                  <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">
                      Sign In
                    </h1>
                   
                  </div>
                  
                  <div className="separator separator-content my-14">
                    <span className="w-125px text-gray-500 fw-semibold fs-7">Enter Email</span>
                  </div>
                  <div className="fv-row mb-8">
                    <input type="text" placeholder="Email" value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" className="form-control bg-transparent" /> 
                  </div>
                  {/* <div className="fv-row mb-3">    
                    <input type="password" placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent" />
                  </div> */}
                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div />
                    {/* <a href="/forgotpassword" className="link-info">
                      Forgot Password ?
                    </a> */}
                  </div>
                  <div className="d-grid mb-10">
                    <button type="submit" onClick={handleLoginSubmit}  className="btn btn-info">
                      <span className="indicator-label">
                        Submit</span>
                     
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                  
                    <a href="/" className="link-info">
                    Back to Login
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
   </div>
               
        );
 
  };

export default Signin;
