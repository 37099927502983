import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();

  const signout = (e) => {
    localStorage.removeItem('ladpsouthafricaaccesstoken');
    localStorage.removeItem('ladpsouthafricafirstname');
    localStorage.removeItem('ladpsouthafricauserid');
    window.location.href = '/';
}


  const isHomeActive = location.pathname === '/home';
  const isOrganizationsActive = location.pathname === '/organizations' || location.pathname === '/neworganization' || location.pathname === '/vieworganization';
  const isUsersActive = location.pathname === '/user' || location.pathname === '/adduser'|| location.pathname === '/viewuser';
  const isFilterActive = location.pathname === '/filter' ;
  const isQuoteActive = location.pathname === '/allquotes'|| location.pathname === '/quote'|| location.pathname === '/viewquote'
  const isSettingsActive = location.pathname === '/settings';
  const isSupplierActive = location.pathname === '/suppliers' || location.pathname === '/newsupplier' || location.pathname === '/viewsupplier' || location.pathname === '/editsupplier'|| location.pathname === '/uploadsupplier'  ;

  const username = localStorage.getItem('ladpsouthafricafirstname');
  const email = localStorage.getItem('ladpsouthafricaemail');

  

  return (
    <div>
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
          <div className=" container-xxl  d-flex flex-grow-1 flex-stack">		
            <div className="d-flex align-items-center me-5">
              <div className="d-lg-none btn btn-icon btn-active-color-primary w-30px h-30px ms-n2 me-3" id="kt_header_menu_toggle">
                <i className="ki-duotone ki-abstract-14 fs-1"><span className="path1" /><span className="path2" /></i>
                </div>
              <a href="home">
                <img alt="Logo" src="assets/media/bglogo.png" className="h-25px h-lg-70px me-2 me-lg-9" />
              </a>
              <div className="d-flex align-items-center">
                {/* <div className="d-none d-lg-inline text-white fw-bold fs-4">Supplier Directory Management</div> */}
              </div>
            </div>
            <div className="topbar d-flex align-items-stretch flex-shrink-0" id="kt_topbar">
              <div className="d-flex align-items-center ms-2 ms-lg-4" id="kt_header_user_menu_toggle">
                <div className="cursor-pointer symbol symbol-30px symbol-lg-40px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                  <img className="symbol symbol-30px symbol-lg-40px" src="assets/media/svg/avatars/blank-dark.svg" alt="user" />
                </div>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                        <img alt="Logo" src="assets/media/svg/avatars/blank-dark.svg" />
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                        {username}            
                        </div>
                        <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">
                          {email}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="separator my-2" />
                  {/*end::Menu separator*/}
                  {/*begin::Menu item*/}
                  {/* <div className="menu-item px-5">
                    <a href="profile" className="menu-link px-5">
                      My Profile
                    </a>
                  </div> */}
                
                  <div className="separator my-2" />
             
                  <div className="menu-item px-5 my-1">
                    <a href="/settings" className="menu-link px-5">
                      Change Password
                    </a>
                  </div>
                  <div className="menu-item px-5">
                    <a onClick={signout} className="menu-link px-5">
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          <div className="header-menu-container d-flex align-items-stretch flex-stack h-lg-75px w-100" id="kt_header_nav">
            <div className="header-menu  container-xxl  flex-column align-items-stretch flex-lg-row" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">  
              <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-500 fw-semibold my-5 my-lg-0 align-items-stretch flex-grow-1 px-2 px-lg-0" id="#kt_header_menu" data-kt-menu="true"> 
                
                

                <a href="/home" className={`menu-item ${isHomeActive ? 'here show menu-here-bg' : ''} menu-lg-down-accordion me-0 me-lg-2`}>
                  <span className="menu-link py-3"> 
                    <span className="menu-title">Home</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </a>

                <a href="/suppliers" className={`menu-item ${isSupplierActive ? 'here show menu-here-bg' : ''} menu-lg-down-accordion me-0 me-lg-2`}>
                  <span className="menu-link py-3">
                    <span className="menu-title">Suppliers</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                  </a>

                <a href="/organizations" className={`menu-item ${isOrganizationsActive ? 'here show menu-here-bg' : ''} menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2`}>
                  <span className="menu-link py-3">
                    <span className="menu-title">Organisations</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </a>


                <a href="/user" className={`menu-item ${isUsersActive ? 'here show menu-here-bg' : ''} menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2`}>
                  <span className="menu-link py-3">
                    <span className="menu-title">Users</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </a>

                <a href="/filter" className={`menu-item ${isFilterActive ? 'here show menu-here-bg' : ''} menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2`}>
                  <span className="menu-link py-3">
                    <span className="menu-title">Create Filter</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </a>

                <a href="/allquotes" className={`menu-item ${isQuoteActive ? 'here show menu-here-bg' : ''} menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2`}>
                  <span className="menu-link py-3">
                    <span className="menu-title">Quotes</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </a>

                <a href="/settings" className={`menu-item ${isSettingsActive ? 'here show menu-here-bg' : ''} menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2`}>
                  <span className="menu-link py-3">
                    <span className="menu-title">Settings</span>
                    <span className="menu-arrow d-lg-none" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
    )
}
export default Header;
