import React, {useState,useEffect} from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import { useHistory } from 'react-router-dom';
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import withReactContent from 'sweetalert2-react-content'
import DataTable from "react-data-table-component";
import Spinner from 'react-bootstrap/Spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";



function Vieworganization(){
  const MySwal = withReactContent(Swal)
  const [org_info, setorg] = useState(['']);
  const [summ_info, setsum] = useState([]);
  const history = useHistory();
  const [int_info, setint] = useState([]);
  const [supplier_summary, setsuppsum] = useState([]);
  const mysum = localStorage.getItem('ladpsouthafricasummaryarray');
  const orgURL = BASE_URL+'/fetchclientsbyID'
  const enableURL = BASE_URL+'/activateClient'
  const disableURL = BASE_URL+'/deactivateClient'
  const summaryURL = BASE_URL+'/fetchClientsummary'
  const interactionsummaryURL = BASE_URL+'/fetchClientInterctionSummary'
  const interactionSupplierURL = BASE_URL+'/fetchClientsummary'
  const token = localStorage.getItem("ladpsouthafricaaccesstoken");
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const parsedSum = JSON.parse(mysum)
  // console.log(parsedSum)
  

  const editorganization = (selectedId) => {
    const selectedID = selectedId;
    if (selectedID) {
      const nextPageUrl = '/vieworganization'; 
      window.location.href = nextPageUrl;
      window.location.href = "editorganization?myid=" + selectedID;
    } else {
      console.error(`Data for ID ${selectedId} not found`);
    }
  }
 

  const headers2 = { 
    headers: {
      'AccessToken': token,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': "*",
    }
  };

  const body = {
    'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
    'client_id': myidd,
    'supplier_id': org_info.id
  }

  const getorginfo = async (e) => {
    axios.post(orgURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setorg(0);
      }else{
        const sortedArray = response.data.data[0];
        console.log(sortedArray)
        setorg(sortedArray);
        setorg(sortedArray);
      }
    });
  };

  const getinteractionsummary = async (e) => {
    axios.post(interactionsummaryURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setint(0);
      }else{
        const sortedArray = response.data.data;
        console.log(sortedArray)
        setint(sortedArray);
        setint(sortedArray);
      }
    });
  };

  const getsuppliersummary = async (e) => {
    axios.post(interactionSupplierURL, body, headers2)
    .then((response) => {
      console.log("response received: ", response);
      if(!response){
        setsuppsum(0);
      }else{
        const sortedArray = response.data.data;
        console.log(sortedArray)
        setsuppsum(sortedArray);
        setsuppsum(sortedArray);
      }
    });
  };

  const getorgsummary = async (e) => {
    axios.post(summaryURL, body, headers2)
      .then((response) => {
        console.log("response received: ", response);
        if (!response || !response.data || !response.data.data || response.data.data.length === 0) {
          setsum(0);
        } else {
          // Assuming that response.data.data[0] contains the value you want to set to sum
          const sortedArray = response.data.data[0];
          setsum(sortedArray);
          console.log(summ_info); // Check if sortedArray has the expected value
          localStorage.setItem('ladpsouthafricasummaryarray', JSON.stringify(sortedArray));
        }
      })
      .catch((error) => {
        console.error("Error fetching org summary:", error);
        setsum(0); // Set sum to 0 in case of error
      });
  };
  

  const enable = async (e) => {
    e.preventDefault();
    if (!myidd) {
      MySwal.fire({
        title: <p>Unable to get Client ID</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
       MySwal.showLoading()
        Swal.close()
        MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(enableURL, body, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             Swal.fire({
               title: "Successful",
               text: "Client Updated successfully",
               icon: "success",
               showCancelButton: false,
               confirmButtonText: "Okay",
               confirmButtonColor: '#3085d6',
               closeOnCancel: true,
             }).then(result => {
           window.location.reload(false);
             })
             })
             .catch((err) => {
             Swal.close()
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };

  const disable = async (e) => {
    e.preventDefault();
    if (!myidd) {
      MySwal.fire({
        title: <p>Unable to get Client ID</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    }else{
       MySwal.showLoading()
        Swal.close()
        MySwal.fire({
           title: <p>please wait...</p>,
           didOpen: () => {
             MySwal.showLoading()
             axios.post(disableURL, body, headers2)
           .then((response) => {
             console.log("response received: ", response);
             Swal.close()
             Swal.fire({
               title: "Successful",
               text: "Client Updated successfully",
               icon: "success",
               showCancelButton: false,
               confirmButtonText: "Okay",
               confirmButtonColor: '#3085d6',
               closeOnCancel: true,
             }).then(result => {
           window.location.reload(false);
             })
             })
             .catch((err) => {
             Swal.close()
               console.log("AXIOS ERROR: ", err);
               if(!err.response){
               return MySwal.fire(err.message)
               }else{
               return MySwal.fire(err.response.data.message)
               }
             })
           },
         }).then(() => {
         })
   }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    getorginfo();
    getorgsummary();
    getinteractionsummary();
    getsuppliersummary();
  }, []);


  const columns = [
    {
      name: 'DATE',
      selector: (row) => dayjs(row.created_at).format("DD MMM YY"),
      sortable: true,
      width: "10rem" ,
        cellExport: (row) => dayjs(row.created_at).format("DD MMM YY")
  },
  {
    name: 'DESCRIPTION ',
    selector: (row) => row.description,
    sortable: true,
    width: '12rem',
    cellExport: row => row.description 

  },

  {
    name: 'SUPPLIER',
    selector: (row) => row.supplier_name,
    sortable: true,
    width: '10rem',
    cellExport: row => row.supplier_name 

  },
  {
    name: 'RATING',
    selector: (row) => row.contact_name,
    sortable: true,
    width: '9rem',
    cellExport: row => row.contact_name 

  },
  {
    name: 'ACTIONS',
    button: true,
    width: '10rem',
    cell: (row) => <button className="btn btn-sm btn-info btn-active-light-primary">View</button>

  },
];

const data=supplier_summary;
const tableData = {
  columns,
  data
}; 

    

      return (
     <div>
     <Header/>
     <div>
        <div className="toolbar py-3 py-lg-5" >
          <div className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                View Organisation
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
          </div>
        </div>
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card mb-5 mb-xl-8">    
                </div>
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
                  <div className="content flex-row-fluid" id="kt_content">
                    <a onClick={handleGoBack} className="btn btn-sm btn-danger">Go back</a>
                    {/* <button style={{float: 'right'}} className="btn btn-sm btn-primary">View all Employees</button> */}
                    <br />
                    <br />
                    <br />
                    <div className="d-flex flex-column flex-xl-row">
                      <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                        <div className="card mb-5 mb-xl-8">
                          <div className="card-body pt-15">
                            <div className="d-flex flex-center flex-column mb-5">
                              <div className="symbol symbol-100px symbol-circle mb-7">
                               {
                                  org_info.status==1?<button onClick={disable} className='btn btn-primary btn-sm' style={{ float: 'left', marginRight: '10px' }}>Disable Client</button> : <button onClick={enable} className='btn btn-success btn-sm' style={{ float: 'left', marginRight: '10px' }}>Enable Client</button>
                                }
                              <button onClick={() => editorganization(org_info.id)} className='btn btn-info btn-sm' style={{ float: 'right', marginLeft: '10px' }}>Edit Info</button>
                              </div>
                              <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">
                              {org_info.clientname}	
                              </a>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                                {org_info.clientlocation}
                              </div>
                              <div className="fs-5 fw-semibold text-muted mb-6">
                              {org_info.business_cat}
                              </div>
                              <div className="d-flex flex-wrap flex-center">
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                    <span className="w-75px">{int_info.completedCount}</span>
                                  </div>
                                  <div className="fw-semibold text-muted">Quotes<br/> Responded</div>
                                </div>
                                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                                  <div className="fs-4 fw-bold text-gray-700">
                                  <span className="w-75px">{int_info.totalCount}</span>
                                  </div>
                                  <div className="fw-semibold text-muted">Quotes <br/> Created</div>
                                </div>
                                
                                
                              </div>
                            </div>
                            <div className="d-flex flex-stack fs-4 py-3">
                              <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_customer_view_details" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
                                Details
                               
                              </div>
                            </div>
                            {/*end::Details toggle*/}
                            <div className="separator separator-dashed my-3" />
                            {/*begin::Details content*/}
                            <div id="kt_customer_view_details" className="collapse show">
                              <div className="py-5 fs-6">
                                {
                                  org_info.status==1?<span className="badge badge-light-info d-inline"> Active</span>: <span className="badge badge-light-danger d-inline"> Disabled</span>
                                }
                                <div className="fw-bold mt-5">Organisation ID</div>
                                <div className="text-gray-600">{org_info.clientregno}</div>
                                <div className="fw-bold mt-5">BBEEE LEVEL</div>
                                <div className="text-gray-600">{org_info.clientbbbee}</div>
                                <div className="fw-bold mt-5">Official Email</div>
                                <div className="text-gray-600"><a href="#" className="text-gray-600 text-hover-primary">{org_info.org_mail}</a></div>
                                <div className="fw-bold mt-5">Official Phone</div>
                                <div className="text-gray-600"><a href="#" className="text-gray-600 text-hover-primary">{org_info.client_phone}</a></div>
                                <div className="fw-bold mt-5">Official  Address</div>
                                <div className="text-gray-600">{org_info.org_address}</div>
                                <div className="fw-bold mt-5">Contact Person </div>
                                <div className="text-gray-600">{org_info.contact_name}</div>
                                <div className="fw-bold mt-5">Contact Person Phone</div>
                                <div className="text-gray-600">{org_info.contact_phone}</div>
                                <div className="fw-bold mt-5">VAT ID</div>
                                <div className="text-gray-600">{org_info.clientvat}</div>
                                <br/>
                                <div className="symbol symbol-100px symbol-circle mb-7">
                                {org_info.bbee_url ? (
                                  <a className='btn btn-primary btn-sm' target='_blank' href={org_info.bbee_url} style={{ float: 'left', marginRight: '10px' }}>View BBEEE Report</a>
                                ) : (
                                  <span>No BBEEE Report</span>
                                )}
                                {org_info.esg_url ? (
                                  <a className='btn btn-primary btn-sm' target='_blank' href={org_info.esg_url} style={{ float: 'left', marginRight: '10px' }}>View ESG Report</a>
                                ) : (
                                  <span>No ESG Report</span>
                                )}
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-lg-row-fluid ms-lg-15">
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
                            <div className="card pt-4 mb-6 mb-xl-9">
                              <div className="card-header border-0">
                                <div className="card-title">
                                  <h2>Suppliers Interactions</h2>
                                </div>
                              </div>
                              <div className="card-body pt-0 pb-5">
                                {/*begin::Table*/}
                                <table className="table align-middle table-row-dashed gy-5" id="kt_table_customers_payment">
                                {supplier_summary == null ? <Spinner animation="border" /> : ""}
                               <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Vieworganization;
