import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import axios from "axios";
import BASE_URL from './config';
import withReactContent from 'sweetalert2-react-content'; 
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import BusinessCategories from './BusinessCategories';
import Select from 'react-select';


function Adduser(){
  const MySwal = withReactContent(Swal);
  const [org_info, setorg] = useState([]);
  const orgURL = BASE_URL+'/fetchclients'
  const [state , setState] = useState({
    firstname: '',
    lastname: '',
    contactphone: '',
    contactaddress: '',
    contactemail: '',
    role: '',
    password: '',
   
})


  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  const token = localStorage.getItem('sl_med_tokenize');
const headers2 = { 
  headers: {
    'AccessToken': JSON.parse(token),
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': "*",
  }
};

const body = {
  'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
}

const getorginfo = async (e) => {
  axios.post(orgURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
      setorg(0);
    }else{
      const sortedArray = response.data.data;
      console.log(sortedArray)
      setorg(sortedArray);
      setorg(sortedArray);
    }
  });
};

useEffect(() => {
  getorginfo();
}, []);


const adduser = async (e) => {
  e.preventDefault();
  if (!state.firstname ||!state.lastname || !state.contactphone|| !state.contactemail|| !state.role|| !state.password) {
    MySwal.fire({
      title: <p>Please enter all required fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  }else{
    MySwal.showLoading()
     const dashURL = BASE_URL+'/register'
     const postData = {
    // 'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
     'firstname': state.firstname,
     'lastname': state.lastname,
     'phonenumber': state.contactphone,
     'email': state.contactemail,
     'address': state.contactaddress,
     'role': state.role,
     'password': state.password,
     'client_id': state.client_id
     }

     console.log(postData);
     MySwal.fire({
         title: <p>please wait...</p>,
         didOpen: () => {
           MySwal.showLoading()
           axios.post(dashURL, postData, headers2)
         .then((response) => {
           console.log("response received: ", response);
           Swal.close()
           Swal.fire({
             title: "Successful",
             text: "User Created successfully",
             icon: "success",
             showCancelButton: false,
             confirmButtonText: "Okay",
             closeOnCancel: true,
             confirmButtonColor: '#3085d6',
           }).then(result => {
         window.location.href = 'user';
           })
           })
           .catch((err) => {
           Swal.close()
             console.log("AXIOS ERROR: ", err);
             if(!err.response){
             return MySwal.fire(err.message)
             }else{
             return MySwal.fire(err.response.data.message)
             }
           })
         },
       }).then(() => {
       })
 }
};
   



      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <br/>
     <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">Add New User</span>
                  
                </h3>
                <div className="card-toolbar">
                 {/* <button style={{float: 'right'}}  className="btn btn-success btn-active-light-success me-2">View Users</button> */}
                 </div>
              </div>
              <div className="card-body py-3">
              <form id="kt_account_profile_details_form" className="form">
                  <div className="card-body border-top p-9">
                    <div className="fv-row mb-12 col-12 row">
                    <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2">Firstname</label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.firstname} id="firstname" name="firstname" onChange={handleChange} />
                      </div>  
                      <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2">Lastname</label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.lastname} id="lastname" name="lastname" onChange={handleChange} />
                      </div>              
                      <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2">Phone </label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactphone} id="contactphone" name="contactphone" onChange={handleChange} />
                      </div>
                    
                    </div>
                  
                    
                    <div className="fv-row mb-12 col-12 row">
                    <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2">Email</label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactemail} id="contactemail" name="contactemail" onChange={handleChange} />
                      </div>
                    <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2"> Address</label>
                        <input type="text" className="form-control  mb-3 mb-lg-0"  value={state.contactaddress} id="contactaddress" name="contactaddress" onChange={handleChange} />
                      </div>
                      <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2"> Create Password</label>
                        <input type="password" className="form-control  mb-3 mb-lg-0"  value={state.password} id="password" name="password" onChange={handleChange} />
                      </div>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <div className='col-lg-4'>
                        <label className="required fw-semibold fs-6 mb-2"> Select Role</label>
                        <select aria-label="Select a Language" value={state.role} id="role" name="role" data-placeholder="Select a category..." onChange={handleChange} className="form-select form-select-solid form-select-lg">
                          <option value>Select a Role...</option>
                          <option  value="client_admin">Client Admin</option>
                          <option  value="sub_admin">Sub Admin</option>
                        </select>
                      </div>
                      <div className='col-lg-4'>
                        <label className=" fw-semibold fs-6 mb-2">Assign to Client</label>
                        <select aria-label="Select" value={state.client_id} id="client_id" name="client_id" onChange={handleChange} className="form-select form-select-solid form-select-lg">
                          <option value="">Select...</option>
                          {org_info.map((org, index) => (
                            <option key={index} value={org.id}>{org.clientname}</option>
                          ))}
                        </select>
                      </div>

                      
                    
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-end py-6 px-9">
                    <a href='user'  className="btn btn-light btn-active-light-danger me-2">Discard</a>
                    <button type="submit" className="btn btn-info" onClick={adduser}>Create User</button>
                  </div>
              </form>
              </div>
            </div>
          </div>
          </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Adduser;
