import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Header2 from './header2'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Countries from './countries';
import Select from 'react-select';
import BASE_URL from './config';


function Orgsettings(){
  const MySwal = withReactContent(Swal);
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [org_info, setorg] = useState(['']);
  const orgURL = BASE_URL+'/fetchSupplierbyID'
  const urlParams = new URLSearchParams(window.location.search);
  const myidd = urlParams.get('myid');
  const [state , setState] = useState({
    current: '',
    newpass: '',
    confirmpass: '',
   
})

const getsuppinfo = async (e) => {
  axios.post(orgURL, body, headers2)
  .then((response) => {
    console.log("response received: ", response);
    if(!response){
      setorg(0);
    }else{
      const sortedArray = response.data.data[0];
      console.log(sortedArray)
      setorg(sortedArray);
      setorg(sortedArray);
    }
  });
};

    const body = {
      'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
      'supplier_id': myidd
    }


    const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
    } 


  useEffect(() => {
    getsuppinfo();
  }, []);

    const handleChange2 = (fieldName) => (selectedOption) => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value
      }));
    };

    const token = localStorage.getItem('sl_med_tokenize');
      const headers2 = { 
        headers: {
          'AccessToken': JSON.parse(token),
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': "*",
        }
      };

      const updatepassword = async (e) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!state.newpass|| !state.confirmpass) {
          MySwal.fire({
            title: <p>Please enter all required fields</p>,
            didOpen: () => {
            },
          }).then(() => {
          });
      }else if(state.newpass !== state.confirmpass) {
            MySwal.fire({
                title: <p>New password and confirm password must match</p>,
                didOpen: () => {},
            }).then(() => {});
      }else if(!passwordRegex.test(state.newpass)) {
            MySwal.fire({
                title: <p>Password must contain at least one uppercase letter, one number, one special character, and be minimum 8 characters</p>,
                didOpen: () => {},
            }).then(() => {});
            return; // Add this line
        }else{
          MySwal.showLoading()
           const dashURL = BASE_URL+'/updatepassword'
           const postData = {
          'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
          'userid': localStorage.getItem("ladpsouthafricauserid"),
          'newPassword': state.newpass
           }
           console.log(postData);
           MySwal.fire({
               title: <p>please wait...</p>,
               didOpen: () => {
                 MySwal.showLoading()
                 axios.post(dashURL, postData, headers2)
               .then((response) => {
                 console.log("response received: ", response);
                 Swal.close()
                 Swal.fire({
                   title: "Successful",
                   text: "Password Updated Successfully",
                   icon: "success",
                   showCancelButton: false,
                   confirmButtonColor: '#3085d6',
                   confirmButtonText: "Okay",
                   closeOnCancel: true,
                 }).then(result => {
               window.location.reload(false);
                 })
                 })
                 .catch((err) => {
                 Swal.close()
                   console.log("AXIOS ERROR: ", err);
                   if(!err.response){
                   return MySwal.fire(err.message)
                   }else{
                   return MySwal.fire(err.response.data.message)
                   }
                 })
               },
             }).then(() => {
             })
       }
      };


      return (
     <div>
     <Header2/>
     <br/>
     <br/>
     <br/>
     <br/>
     <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid ms-lg-15">
        <h3 className="fw-bold m-0">Account Settings</h3>
            <br/>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
        <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Change Password</h3>
          </div>
        </div>
        <div id="kt_account_settings_profile_details" className="collapse show">
          <form id="kt_account_profile_details_form" className="form">
            <div className="card-body border-top p-9">
              <div className="fv-row mb-12 col-12 row">
             
                <div className='col-lg-6'>
                  <label className="required fw-semibold fs-6 mb-2">New Password </label>
                  <input type="password" className="form-control  mb-3 mb-lg-0" value={state.newpass} id="newpass" name="newpass" onChange={handleChange} />
                </div>
                <div className='col-lg-6'>
                  <label className="required fw-semibold fs-6 mb-2">Confirm New Password </label>
                  <input type="password" className="form-control  mb-3 mb-lg-0" value={state.confirmpass} id="confirmpass" name="confirmpass" onChange={handleChange} />
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
              {/* <a href='viewsupplier'  className="btn btn-light btn-active-light-primary me-2">Discard</a> */}
              <button onClick={updatepassword} className="btn btn-info">Submit</button>
            </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br/>

   
    </div>
  </div>
 </div>
</div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Orgsettings;
