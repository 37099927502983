import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Singleproduct(){
    

      return (
     <div>
     <Header/>
     <div className="product-details-area pt-100px pb-100px">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
                <div className="single-product-sticky mb-30px">
                  <div className="single-product-slider-wrapper d-flex flex-wrap">
                    <div className="single-product-slider-item" data-aos="fade-up" data-aos-delay={200}>
                      <a href="#"><img className="img-responsive mb-30px" src="assets/images/homeicon20.jpeg" alt="slider-item-img" /></a>
                    </div>
                    <div className="single-product-slider-item swiper-slide" data-aos="fade-up" data-aos-delay={400}>
                      <a href="#"><img className="img-responsive mb-30px" src="assets/images/homeicon21.jpeg" alt="slider-item-img" /></a>
                    </div>
                    <div className="single-product-slider-item swiper-slide" data-aos="fade-up" data-aos-delay={200}>
                      <a href="#"><img className="img-responsive mb-30px" src="assets/images/homeicon22.jpeg" alt="slider-item-img" /></a>
                    </div>
                    <div className="single-product-slider-item swiper-slide" data-aos="fade-up" data-aos-delay={400}>
                      <a href="#"><img className="img-responsive" src="assets/images/homeicon23.jpeg" alt="slider-item-img" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay={200}>
                <div className="product-details-content quickview-content sticky">
                  <h2>Women Gym wear</h2>
                  <div className="pricing-meta">
                    <ul>
                      <li className="old-price not-cut">$18.90</li>
                    </ul>
                  </div>
                  
                  <p className="mt-30px mb-0">Item description goes in this container accordingly </p>
                  <div className="pro-details-quality">
                  Quantity
                    <div className="cart-plus-minus">
                      <input className="cart-plus-minus-box" type="text" name="qtybutton" defaultValue={1} />
                    </div>
                    <div className="pro-details-cart">
                      <a className="add-cart" href="/cart"> Add To
                        Cart</a>
                    </div>
                    
                  </div>
                  <div className="pro-details-sku-info pro-details-same-style  d-flex">
                    <span>Item ID: </span>
                    <ul className="d-flex">
                      <li>
                        <a href="#">Ch-256xl</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Singleproduct;
